import React, { useState } from "react";
import styled from "styled-components";
import { images, textInputs } from "../../../../../constants/constants";
import { Wrapper } from "../../../../../components/Control/Flexbox";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { CenteredLogoImg } from "../../../../../golbalStyles/global.styled";
import formatDate from "../../../../../components/Control/formatDate";
import { MenuItem, Select, makeStyles, FormControl } from "@material-ui/core";

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const Table = styled.table`
  width: 98%;
  margin: auto;
  margin: 1rem;
  th {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    border: 1px solid black;
    padding: 0.4rem;
    p {
      margin: 0;
    }
  }

  td {
    font-size: 12px;
    border: 1px solid black;
    padding: 0.3rem;
    p {
      margin: 0;
    }
  }
  span {
    font-weight: 500;
  }

  .input {
    outline: none;
    width: 100%;
    border: 2px solid #f9e0e0;
    width: 100px;
    height: 50px;
    text-align: center;
  }
  .text-center th,
  td {
    text-align: center;
  }
`;

const MaintenanceTableForm = ({
  checkListMachineData,
  changeLanguage,
  handleChecklist,
  checklistType,
  isView,
}) => {
  const [render, setRender] = useState(false);

  console.log("Hello preventive called");

  const classes = dropdownStyles();

  const menuItemStyles = {
    display: "flex",
    width: "60px",
    alignItems: "center",
    justifyContent: "center",
  };
  const CustomSelect = (name, value, index, pos, neg, Width) => {
    return (
      <Select
        classes={{
          root: classes.root,
          select: classes.select,
        }}
        inputProps={{ IconComponent: () => null }}
        style={{
          fontSize: "0.8rem",
          width: Width,
          display: "flex",

          alignItems: "center",
          justifyContent: "center",
        }}
        disableUnderline
        name={name}
        value={value}
        onChange={(e) => {
          handleChecklist(e, index);
          setRender((prev) => !prev);
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>

        <MenuItem value={pos}>
          <span
            style={{
              fontSize: "1.3rem",
              color: "#40c057",
              ...menuItemStyles,
            }}
          >
            {pos}
          </span>
        </MenuItem>
        <MenuItem value={neg}>
          <span
            style={{
              fontSize: "1.3rem",
              color: "#f03e3e",
              ...menuItemStyles,
            }}
          >
            {neg}
          </span>
        </MenuItem>
      </Select>
    );
  };

  return (
    <div style={{ paddingBottom: "4rem" }}>
      <Table id={checkListMachineData.document_type} data-default-wrap="true">
        <thead>
          <tr>
            <th
              data-img={images.arcLogo}
              colSpan="6"
              rowSpan={2}
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
              }}
            >
              <CenteredLogoImg>
                <img
                  src={images.arcLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>PREVENTIVE MAINTENANCE CHECKLIST</span>
              </CenteredLogoImg>
            </th>
            <th align="left" colSpan={2}>
              Doc.No.:-FM/XLV/MT/008
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={2}>
              FOR THE MONTH :
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={3}>
              Machine Name :- {checkListMachineData.machine_name}
            </th>
            <th align="left" rowSpan={2} colSpan={5}>
              SCHEDULE : MONTHLY (M) / QUATERLY (Q) / HALF YEARLY (H) / ANNUALY
              (A)
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={3}>
              Machine Name :- {checkListMachineData.machine_no}
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={3}>
              Dept./Section :-
              {isView ? (
                checkListMachineData.department
              ) : (
                <input
                  autoComplete="off"
                  className="input"
                  style={{ border: "2px solid #f9e0e0", width: "200px" }}
                  name="department"
                  defaultValue={checkListMachineData.department}
                  onChange={(e) => {
                    checkListMachineData.department = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
            <th align="left" colSpan={4}>
              Schedule :
              {isView ? (
                checkListMachineData.schedule
              ) : (
                <input
                  style={{ border: "2px solid #f9e0e0", width: "200px" }}
                  autoComplete="off"
                  className="input"
                  name="schedule"
                  defaultValue={checkListMachineData.schedule}
                  onChange={(e) => {
                    checkListMachineData.schedule = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
            <th align="left" colSpan={1}>
              Date:-
              {isView ? (
                formatDate(checkListMachineData.last_maintenance_date)
              ) : (
                <Wrapper style={{ gap: "5px" }}>
                  <DatePicker
                    name="last_maintenance_date"
                    format="dd/MM/yyyy"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{
                      backgroundColor: "#f8f9fa",
                    }}
                    value={checkListMachineData.last_maintenance_date || null}
                    onChange={(date) => {
                      checkListMachineData.last_maintenance_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                </Wrapper>
              )}
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={3}>
              Maintenance Details :-
              {
                <FormControl variant="filled" className="select-field">
                  <Select
                    style={{ width: "200px" }}
                    name="maintenance_details"
                    onChange={(e) => {
                      checkListMachineData.maintenance_details = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    value={checkListMachineData.maintenance_details}
                  >
                    <MenuItem value="All OK as per checksheet">
                      All OK as per checksheet
                    </MenuItem>
                    <MenuItem value="other">other</MenuItem>
                  </Select>
                </FormControl>
              }
            </th>
            <th align="left" colSpan={3}>
              {checkListMachineData.maintenance_details !== undefined &&
                checkListMachineData.maintenance_details !==
                  "All OK as per checksheet" &&
                (isView ? (
                  checkListMachineData.maintenance_details
                ) : (
                  <input
                    style={{ border: "2px solid #f9e0e0", width: "200px" }}
                    autoComplete="off"
                    className="input"
                    name="maintenance_details"
                    value={checkListMachineData.maintenance_details}
                    onChange={(e) => {
                      checkListMachineData.maintenance_details = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                ))}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th rowSpan={2} style={{ width: "100px" }}>
              S NO.
            </th>
            <th rowSpan={2} style={{ width: "550px" }}>
              CHECK POINTS
            </th>
            <th colSpan={4}>FREQUENCY OF MAINT.</th>
            <th> OBSERVATIONS</th>
            <th rowSpan={2} style={{ width: "20px" }}>
              REMARKS
            </th>
          </tr>
          <tr>
            <th style={{ width: "60px" }}>M</th>
            <th style={{ width: "60px" }}>H</th>
            <th style={{ width: "60px" }}>Q</th>
            <th style={{ width: "60px" }}>A</th>
            <th></th>
          </tr>
          {checkListMachineData.checkListMachineData[checklistType].map(
            (item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{index + 1}</td>
                  <td>{item[`check_point`]}</td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",

                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView
                      ? item.freq_m
                      : CustomSelect(
                          "freq_m",
                          item.freq_m,
                          index,
                          "✓",
                          "✖",
                          "70px"
                        )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView
                      ? item.freq_q
                      : CustomSelect(
                          "freq_q",
                          item.freq_q,
                          index,
                          "✓",
                          "✖",
                          "70px"
                        )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView
                      ? item.freq_h
                      : CustomSelect(
                          "freq_h",
                          item.freq_h,
                          index,
                          "✓",
                          "✖",
                          "70px"
                        )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView
                      ? item.freq_a
                      : CustomSelect(
                          "freq_a",
                          item.freq_a,
                          index,
                          "✓",
                          "✖",
                          "70px"
                        )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView ? (
                      item.observation
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="observation"
                        defaultValue={item.observation}
                        style={{ width: "100%" }}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView
                      ? item.remarks
                      : CustomSelect(
                          "remarks",
                          item.remarks,
                          index,
                          "OK",
                          "NOT OK",
                          "100px"
                        )}
                  </td>
                </tr>
              </React.Fragment>
            )
          )}
        </tbody>
        <tr>
          <td align="left" colSpan={3}>
            Electrician / Fitter Signature:
            {isView ? (
              checkListMachineData.electrician_sign
            ) : (
              <input
                autoComplete="off"
                className="input"
                style={{ border: "2px solid #f9e0e0", width: "200px" }}
                name="maintenance_engg"
                defaultValue={checkListMachineData.electrician_sign}
                onChange={(e) => {
                  checkListMachineData.electrician_sign = e.target.value;
                }}
              />
            )}
          </td>
          <td align="left" colSpan={3}>
            Maintenance Engg.:
            {isView ? (
              checkListMachineData.maintenance_engg
            ) : (
              <input
                autoComplete="off"
                className="input"
                name="maintenance_engg"
                style={{ border: "2px solid #f9e0e0", width: "200px" }}
                defaultValue={checkListMachineData.maintenance_engg}
                onChange={(e) => {
                  checkListMachineData.maintenance_engg = e.target.value;
                }}
              />
            )}
          </td>
          <td align="left" colSpan={2}>
            Preventive Maintenance date:
            {checkListMachineData.preventive_time}
            {/* {isView ? ( */}
            {/*   formatDate(checkListMachineData.preventive_date) */}
            {/* ) : ( */}
            {/*   <Wrapper style={{ gap: "5px" }}> */}
            {/*     <DatePicker */}
            {/*       name="preventive_date" */}
            {/*       format="dd/MM/yyyy" */}
            {/*       InputProps={{ */}
            {/*         disableUnderline: true, */}
            {/*       }} */}
            {/*       style={{ */}
            {/*         backgroundColor: "#f8f9fa", */}
            {/*       }} */}
            {/*       value={checkListMachineData.preventive_date || null} */}
            {/*       onChange={(date) => { */}
            {/*         checkListMachineData.preventive_date = date; */}
            {/*         setRender((prev) => !prev); */}
            {/*       }} */}
            {/*     /> */}
            {/*   </Wrapper> */}
            {/* )} */}
          </td>
        </tr>
      </Table>
    </div>
  );
};

export default MaintenanceTableForm;
