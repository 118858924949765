import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";

import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import useConfirm from "../../components/Control/useConfirm";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const DailyProductionTable = () => {
  const classes = useStyles();
  const { state } = useAuth();
  const [dailyproductionData, setDailyProductionData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [render, setRender] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    "SAVE",
    "Are you sure you want to Delete this report?"
  );

  useEffect(() => {
    axios
      .get(`/api/getDailyProduction/all/${selectedDate}/${state.user.userType}`)
      .then((res) => setDailyProductionData(res.data));
  }, [selectedDate, render]);

  const handleDelete = async (id) => {
    // const isConfirm = window.confirm(
    //   "Are you sure you want to delete this document"
    // );
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      axios
        .delete(`/api/deleteDailyProduction/${id}`)
        .then((res) => {
          alert("success deleted");
          setRender((prev) => !prev);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <section>
      <div style={{}}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
          }}
        >
          DAILY PRODUCTION REPORT
          <div
          // className="text"
          >
            {(state.user.userType === 3 || state.user.userType === 2) && (
              <Link
                to={`/createDailyProduction/table`}
                style={{ color: "white", textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    // marginBottom: "2rem",

                    backgroundColor: "#054a29",
                  }}
                  startIcon={<AddCircleIcon />}
                >
                  New Report
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="subheading-container">
          <p className="subheading-primary">
            ACTIVE <span className="subheading-secondary">REPORTS</span>
          </p>
        </div>
      </div>
      <div style={{ display: "flex", margin: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate || null}
          onChange={setSelectedDate}
        />
      </div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            {["#", "Department", "Status", "Created At", "Action"].map(
              (item, index) => (
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  {item}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        {dailyproductionData && (
          <TableBody>
            {dailyproductionData.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.department}</TableCell>

                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: item.isSubmitted ? "green" : "yellow",
                      color: item.isSubmitted ? "white" : "black",
                      width: "250px",
                    }}
                  >
                    {item.isSubmitted ? "SUBMITTED" : "DRAFT"}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.dailyProduction_date)}
                  </TableCell>

                  <TableCell align="center">
                    <Tooltip title="View Daily Production" arrow>
                      <Link to={`/viewDailyProductiontable/${item._id}`}>
                        <IconButton size="small">
                          <VisibilityIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Edit Daily Production" arrow>
                      <Link to={`/createDailyProduction/table/${item._id}`}>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <EditIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Delete Daily Production" arrow>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(item._id)}
                        style={{ marginLeft: "0.5rem" }}
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "1.4rem",
                            color: "#c80202",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <DialogDelete isSubmitContent={true} />
    </section>
  );
};

export default DailyProductionTable;
