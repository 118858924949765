import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import { Add, Remove } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import ForMTable from "../DailyProduction/ForMTable";
import Modal from "../../components/Control/Modal";
import useConfirm from "../../components/Control/useConfirm";
import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";
import SelectPart from "../DailyProduction/SelectPart";
import { Autocomplete } from "@material-ui/lab";

const Table = Styled.table`
	margin-bottom: 1rem;
  width: 100%;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

`;

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const ProductionPlanningTable = () => {
  const classes = dropdownStyles();
  const index4m = useRef(0);
  const tabValue4m = useRef(0);
  const history = useHistory();
  const { id } = useParams();
  const [is4mModal, setIs4mModal] = useState(false);
  const [render, setRender] = useState(false);
  const [partDetails, setPartDetails] = useState();
  const [productionPlanningData, setProductionPlanningData] = useState({
    processes: new Array(25).fill().map((_, index) => ({ sr_no: index + 1 })),
  });
  const [isSelectPartModal, selectSelectPartModal] = useState(false);
  const indexSelectPart = useRef(0);

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save document"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE",
    "Are you sure you want to update document"
  );
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    axios
      .get("/api/getAllDepartment")
      .then((res) => {
        if (res.data) {
          const results = res.data.map((item) => {
            return {
              id: item._id,
              department_name: item.department_name,
            };
          });
          setDepartments(results);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/getPartDetails`)
      .then((res) => {
        setPartDetails(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/getOneProductionPlanning/${id}`)
      .then((res) => {
        setProductionPlanningData(res.data);
        if (res.data) {
          const values = res.data.processes.map((process, index) => {
            if (typeof process.department_id === "object") {
              return {
                id: process.department_id._id,
                department_name: process.department_id.department_name,
              };
            }
          });
          setAutoCompleteValue(values);
        }
      })
      .catch((err) => console.log(err));
  }, []);
    console.log(departments)
  console.log(autoCompleteValue);

  const handleChangeProduction = (e, index) => {
    const value = e.target.innerText;
    const name = e.target.dataset.name;
    productionPlanningData.processes[index][name] = value;
  };

  const handle4m = (value, tabValue) => {
    if (tabValue === 0) {
      productionPlanningData.processes[index4m.current].machine_no = value;
    }
    if (tabValue === 1) {
      productionPlanningData.processes[index4m.current].operator_name = value;
    }
    setRender((prev) => !prev);
  };
  const reset4m = (tabValue) => {
    if (tabValue === 0) {
      productionPlanningData.processes[index4m.current].machine_no = null;
    }
    if (tabValue === 1) {
      productionPlanningData.processes[index4m.current].operator_name = null;
    }
    setRender((prev) => !prev);
  };

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      const isConfirm = await confirmUpdate();
      if (isConfirm) {
        productionPlanningData.isSubmitted = bool;
        axios
          .put(`/api/updateProductionPlanning/${id}`, productionPlanningData)
          .then((res) => {
            if (res.data.status === "success") {
              history.push("/productionPlanningcontrol/create");
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      const isConfirm = await confirmSave();
      if (isConfirm) {
        productionPlanningData.isSubmitted = bool;
        axios
          .post(`/api/productionPlanning/create`, productionPlanningData)
          .then((res) => {
            if (res.data.status === "success") {
              history.push("/productionPlanningcontrol/create");
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handlePartDetails = (partId, value) => {
    productionPlanningData.processes[indexSelectPart.current].part_id = partId;
    productionPlanningData.processes[indexSelectPart.current].part_number =
      value;
    setRender((prev) => !prev);
  };

  const resetPartDetails = () => {
    productionPlanningData.processes[indexSelectPart.current].part_id = null;
    productionPlanningData.processes[indexSelectPart.current].part_number =
      null;
    productionPlanningData.processes[indexSelectPart.current].part_name = null;
    setRender((prev) => !prev);
  };
  return (
    <section>
      <div style={{ paddingBottom: "4rem" }}>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
              textTransform: "uppercase",
            }}
          >
            Production Planning & Control Report
          </Typography>
          <div style={{ display: "flex" }}>
            <Wrapper
              style={{
                gap: "1rem",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#003566",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  handleSubmitAndSave(false);
                }}
                startIcon={<SaveIcon />}
              >
                Save & Continue
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#064a29",
                  marginRight: "0.5rem",
                }}
                startIcon={<CloudUploadIcon />}
                onClick={() => {
                  handleSubmitAndSave(true);
                }}
              >
                Submit
              </Button>
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (ans) {
                    history.goBack();
                  }
                }}
              />
            </Wrapper>
          </div>
        </div>

        <div
          style={{
            margin: "0.1rem 0.5rem",
            paddingBottom: "4rem",
            overflowX: "scroll",
          }}
        >
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
            id="table-to-xls"
          >
            <thead>
              <tr data-height="80" style={{ height: "70px" }}>
                <th
                  colSpan="9"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC NO:ARC/F/PPC/01
                </th>
              </tr>

              <tr>
                <th
                  colSpan="9"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  Daily Production Plan v/s Actual
                </th>
                <th style={{ border: "1px solid black" }} data-f-sz="10">
                  REV.NO/DATE-02/07/2019{" "}
                </th>
              </tr>
              <tr>
                <th colSpan={8} style={{ border: "1px solid black" }}>
                  <Wrapper style={{ gap: "5px" }}>
                    Date:-
                    <DatePicker
                      // label="pic Date"
                      name="date_performance"
                      format="dd/MM/yyyy"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#f8f9fa",
                      }}
                      value={productionPlanningData.productionPlanning_date}
                      onChange={(date) => {
                        productionPlanningData.productionPlanning_date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  </Wrapper>
                </th>
                <th style={{ border: "1px solid black" }}>Shift</th>
                <th
                  align="center"
                  contenteditable="true"
                  data-name="shift"
                  onKeyUp={(e) => {
                    productionPlanningData[e.target.dataset.name] =
                      e.target.innerText;
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {productionPlanningData.shift}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "Sr. No",
                  "Machine No.",
                  "Department Name",
                  "Sch Qty",
                  "Lot Qty",
                  "Part No.",
                  "Production Plan",
                  "Actual Qty",
                  "Heat No.",
                  "Remarks",
                ].map((heading, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
              {productionPlanningData.processes.map((item, index) => (
                <tr key={item._id || index}>
                  <td>{item.sr_no}</td>
                  <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <Wrapper
                      justify="space-between"
                      style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                    >
                      <p
                        style={{
                          fontSize: "0.83rem",
                        }}
                      >
                        {item.machine_no || "Pick Machine"}
                      </p>
                      {item.machine_no ? (
                        <Wrapper
                          className="pir_add_modal"
                          style={{ backgroundColor: "#003566" }}
                          justify="center"
                          onClick={() => {
                            item.machine_no = null;
                            setRender((prev) => !prev);
                          }}
                        >
                          <Remove />
                        </Wrapper>
                      ) : (
                        <Wrapper
                          className="pir_add_modal"
                          justify="center"
                          style={{ backgroundColor: "#495057" }}
                          onClick={() => {
                            index4m.current = index;
                            tabValue4m.current = 0;
                            setIs4mModal(true);
                          }}
                        >
                          <Add />
                        </Wrapper>
                      )}
                    </Wrapper>
                  </th>
                  <td>
                    <Autocomplete
                      id="department_id"
                      options={departments}
                      getOptionLabel={
                        ((option) => option.department_name) || []
                      }
                      value={autoCompleteValue[index] || "" }
                      style={{
                        border: "1px solid #ced4da",
                        minWidth: "230px",
                        height: "45px",
                      }}
                      onChange={(e, value) => {
                        item.department_id = value?._id;
                        const values = { ...autoCompleteValue };
                        values[index] = value;
                        setAutoCompleteValue(values);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          // style={{ border: "1px solid #ced4da" }}
                        />
                      )}
                    />
                  </td>
                  <td
                    align="center"
                    contenteditable="true"
                    data-name="sch_qty"
                    onKeyUp={(e) => {
                      handleChangeProduction(e, index);
                    }}
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    {item.sch_qty}
                  </td>
                  <td
                    align="center"
                    contenteditable="true"
                    data-name="lot_qty"
                    onKeyUp={(e) => {
                      handleChangeProduction(e, index);
                    }}
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    {item.lot_qty}
                  </td>
                  {/* <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    <FormControl
                      style={{
                        width: "250px",
                        border: "none",
                      }}
                      fullWidth={true}
                      variant="filled"
                      classes={{
                        root: classes.root,
                      }}
                    >
                      <InputLabel>Select Part No</InputLabel>
                      <Select
                        defaultValue={item.part_no || ""}
                        classes={{
                          // underline: classes.underline,
                          root: classes.root,
                          select: classes.select,
                        }}
                        disableUnderline
                        onChange={(e) =>
                          (productionPlanningData.processes[index].part_no =
                            e.target.value)
                        }
                      >
                        {partDetails &&
                          partDetails.map((data, PIndex) => (
                            <MenuItem
                              className="option"
                              key={PIndex}
                              value={data.part_number}
                            >
                              {data.part_number}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </th> */}
                  <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <Wrapper
                      justify="space-between"
                      style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                    >
                      <p
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        {item?.part_id?.part_number ||
                          item?.part_number ||
                          "Pick Part No."}
                      </p>
                      {item?.part_number || item?.part_id?.part_number ? (
                        <Wrapper
                          className="pir_add_modal"
                          style={{ backgroundColor: "#003566" }}
                          justify="center"
                          onClick={() => {
                            item.part_number = null;
                            item.part_id = null;

                            setRender((prev) => !prev);
                          }}
                        >
                          <Remove />
                        </Wrapper>
                      ) : (
                        <Wrapper
                          className="pir_add_modal"
                          justify="center"
                          style={{ backgroundColor: "#495057" }}
                          onClick={() => {
                            indexSelectPart.current = index;
                            selectSelectPartModal(true);
                          }}
                        >
                          <Add />
                        </Wrapper>
                      )}
                    </Wrapper>
                  </th>
                  <td
                    align="center"
                    contenteditable="true"
                    data-name="production_plan"
                    onKeyUp={(e) => {
                      handleChangeProduction(e, index);
                    }}
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    {item.production_plan}
                  </td>
                  <td
                    align="center"
                    contenteditable="true"
                    data-name="actual_qty"
                    onKeyUp={(e) => {
                      handleChangeProduction(e, index);
                    }}
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    {item.actual_qty}
                  </td>

                  <td
                    align="center"
                    contenteditable="true"
                    data-name="heat_no"
                    onKeyUp={(e) => {
                      handleChangeProduction(e, index);
                    }}
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    {item.heat_no}
                  </td>
                  <td
                    align="center"
                    contenteditable="true"
                    data-name="remark"
                    onKeyUp={(e) => {
                      handleChangeProduction(e, index);
                    }}
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    {item.remark}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        openPopup={is4mModal}
        title="4M DATABASE"
        closeModal={(e) => setIs4mModal(false)}
      >
        <ForMTable
          {...{ handle4m, reset4m }}
          processes={productionPlanningData.processes[index4m.current]}
          index4m={index4m.current}
          tabValue4m={tabValue4m.current}
          dontShowMan={true}
        />
      </Modal>
      <Modal
        openPopup={isSelectPartModal}
        title="Select PART"
        closeModal={(e) => selectSelectPartModal(false)}
      >
        <SelectPart
          {...{ handlePartDetails, resetPartDetails }}
          processes={productionPlanningData.processes[indexSelectPart.current]}
        />
      </Modal>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default ProductionPlanningTable;
