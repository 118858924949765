import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import Styled from "styled-components";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import PrintIcon from "@material-ui/icons/Print";

import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	margin-bottom: 1rem;
  width: 100%;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

`;

function calculateWorkingHour(time1, time2) {
  if (!time1 && !time2) return "";
  if (new Date(time2).getTime() < new Date(time1).getTime()) {
    return "Choose valid time period";
  }
  const msInHour = 1000 * 60 * 60;
  let workingHour = (new Date(time2) - new Date(time1)) / msInHour;
  if (isNaN(workingHour)) {
    return "";
  }
  let timeArr = workingHour.toString().split(".");
  let hour = timeArr[0];
  let min = 0;
  if (timeArr[1]) {
    min = `${Math.ceil((+timeArr[1].slice(0, 2) / 100) * 60)}`.padEnd(2, 0);
  }
  if (min == 60) {
    min = 0;
  }
  return `${hour} hr ${min} min`;
}

function formatDateForTime(date) {
  const newDate = new Date(date);
  if (isNaN(newDate)) {
    return "";
  }
  let hour = newDate.getHours();
  const min = newDate.getMinutes().toString().padEnd(2, 0);
  let meridian = "AM";
  if (hour > 12) {
    hour = hour - 12;
    meridian = "PM";
  }
  if (hour === 12) {
    meridian = "PM";
  }
  if (hour === 0) {
    hour = 12;
    meridian = "AM";
  }
  return `${hour}:${min} ${meridian}`;
}

function calculateWorkingEff(time1, time2, cycle_time, producedQty) {
  if (new Date(time2).getTime() < new Date(time1).getTime()) {
    return "Choose valid time period";
  }
  let workingHour =
    Math.abs(new Date(time2).getTime() - new Date(time1).getTime()) / 3600000;
  if (isNaN(workingHour)) return "";
  const idealQty = (workingHour * 3600) / +cycle_time;
  const workEff = (producedQty / idealQty) * 100;
  return workEff.toFixed(2);
}
const ViewDailyProductionTable = () => {
  const [dailyProductionData, setDailyProductionData] = useState();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/getOneDailyProduction/${id}`)
      .then((res) => {
        setDailyProductionData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  return (
    <section>
      <div style={{ paddingBottom: "4rem" }}>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            DAILY PRODUCTION REPORT
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip arrow title="Print table">
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: "Daily Production.xlsx",
                        sheet: {
                          name: "Daily Production",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            margin: "0.1rem 0.5rem",
            paddingBottom: "4rem",
            overflowX: "scroll",
          }}
        >
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 20, 8, 10, 10, 12, 10, 14, 12, 12, 15, 14, 12, 12, 12, 14, 14, 12, 14, 14, 12 ,12, 12"
          >
            <thead>
              <tr data-height="80" style={{ height: "80px" }}>
                <th
                  colSpan="23"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  colSpan="3"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                ></th>
              </tr>

              <tr>
                <th
                  colSpan="23"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  DAILY PRODUCTION REPORT
                </th>

                <th
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REV. NO.:- 00/01/07/2018
                </th>
              </tr>
              <tr data-height="25">
                <th
                  colSpan={8}
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  DATE: {formatDate(dailyProductionData?.dailyProduction_date)}
                </th>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  SHIFT:
                </th>
                <th
                  colSpan={6}
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {dailyProductionData?.shift}
                </th>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  DEPARTMENT:
                </th>
                <th
                  colSpan={8}
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {dailyProductionData?.department}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr data-height="40">
                {[
                  "M/C NO.",
                  "OPERATOR NAME",
                  "PART NO.",
                  "PART NAME",
                  "OPERATION NO",
                  "LOT NO.",
                  "IN TIME",
                  "OUT TIME",
                  "WORKING HOUR",
                  "CYCLE TIME (IN SEC)",
                  "PRODUCTION",
                  "REJECTION",
                  "Reason of Failure",
                  "BREAKDOWNS",
                  "BREAKDOWN REASON",
                ].map((heading, index) => (
                  <th
                    key={index}
                    rowSpan={2}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  colSpan={2}
                >
                  BREAKDOWN TIME
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  rowSpan={2}
                >
                  M/C HELD UP TIME
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  rowSpan={2}
                >
                  BREAKDOWN ATTENDED BY
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  rowSpan={2}
                >
                  BREAKDOWN WORK DONE
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  rowSpan={2}
                >
                  SPARE PARTS USED
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  rowSpan={2}
                >
                  BREAKDOWN QTY
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  rowSpan={2}
                >
                  BREAKDOWN REMARKS
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  rowSpan={2}
                >
                  REWORK
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  rowSpan={2}
                >
                  WORK EFF %
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                  rowSpan={2}
                >
                  REMARK
                </th>
              </tr>
              <tr data-height="40">
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  FROM
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  TO
                </th>
              </tr>
            </tbody>
            {dailyProductionData &&
              dailyProductionData.processes.map((item, index) => (
                <tr key={item._id || index} height="50px" data-height="60">
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.machine_no}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.operator_name}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.part_id?.part_number}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.part_name}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.process_name}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.lot_no}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black", minWidth: "90px" }}
                    data-f-sz="10"
                  >
                    {formatDateForTime(item.in_time)}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black", minWidth: "90px" }}
                    data-f-sz="10"
                  >
                    {formatDateForTime(item.out_time)}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black", minWidth: "120px" }}
                    data-f-sz="10"
                  >
                    {calculateWorkingHour(item.in_time, item.out_time)}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.cycle_time}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.production}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.rejection}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.reason}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.breakdown}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.breakdown_reason === "OTHER"
                      ? item.breakdown_reason_other
                      : item.breakdown_reason}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {formatDateForTime(item.breakdown_time_from)}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {formatDateForTime(item.breakdown_time_to)}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      minWidth: "90px",
                    }}
                  >
                    {calculateWorkingHour(
                      item.breakdown_time_from,
                      item.breakdown_time_to
                    )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      minWidth: "90px",
                    }}
                  >
                    {item.breakdown_attended}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      minWidth: "90px",
                    }}
                  >
                    {item.breakdown_work_done}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      minWidth: "90px",
                    }}
                  >
                    {item.spare_parts}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      minWidth: "90px",
                    }}
                  >
                    {item.breakdown_qty}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      minWidth: "90px",
                    }}
                  >
                    {item.breakdown_remarks}
                  </td>
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.rework}
                  </td>

                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {calculateWorkingEff(
                      item.in_time,
                      item.out_time,
                      item.cycle_time,
                      item.production
                    )}
                  </td>
                  {/* <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.oee}
                  </td> */}
                  {/* <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.operator_eff}
                  </td> */}
                  <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.remark}
                  </td>
                </tr>
              ))}
          </Table>
        </div>
      </div>
    </section>
  );
};

export default ViewDailyProductionTable;
