import React, { useEffect, useState, useRef } from "react";
import "../../style/product.css";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { DivStyled } from "./Container.styled";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add, Remove } from "@material-ui/icons";

const ComplainList = ({
    customerTrackerData,
    setCustomerTrackerData,
    autoCompleteValue,
    allcustomerData,
    setRender,
    setAutoCompleteValue,
    setModalCloseBtn,
    isOnlyViewModal,
}) => {
    function handleCustomerData(e) {
        setCustomerTrackerData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }

    return (
        <section className="root-admin">
            <DivStyled num={1}>
                <label for="date_of_complaint">Date Of Complaint :</label>
                <DatePicker
                    name="date_of_complaint"
                    format="dd/MM/yyyy"
                    InputProps={{
                        disableUnderline: true,
                    }}
                    style={{
                        borderLeft: "2px solid #ced4da",
                        paddingLeft: "0.5rem",
                    }}
                    value={customerTrackerData?.date_of_complaint}
                    readOnly={isOnlyViewModal}
                    onChange={(date) => {
                        customerTrackerData.date_of_complaint = date;
                        setRender((prev) => !prev);
                    }}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label for="customer_id">Customer Name :</label>
                {isOnlyViewModal ? (
                    <input
                        defaultValue={customerTrackerData?.customer_id?.customer_name}
                        readOnly
                    />
                ) : (
                    <Autocomplete
                        id="customer_id"
                        options={allcustomerData || []}
                        getOptionLabel={(option) => option.customer_name}
                        value={autoCompleteValue}
                        style={{
                            borderLeft: "2px solid #ced4da",
                            paddingLeft: "0.5rem",
                            width: "100%",
                            marginRight: "6rem",
                        }}
                        onChange={(e, value) => {
                            customerTrackerData.customer_id = value?.id;
                            if (!value?.id) customerTrackerData.customer_id = null;
                            setAutoCompleteValue(value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                            />
                        )}
                    />
                )}
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="part_id">Part Name/no :</label>
                <Wrapper
                    justify="space-between"
                    style={{
                        borderLeft: "2px solid #ced4da",
                        paddingLeft: "0.5rem",
                        width: "100%",
                        height: "35px",
                        backgroundColor: "#fff",
                    }}
                >
                    <p
                        style={{
                            fontSize: "1rem",
                            marginBottom: "0",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {customerTrackerData?.part_id?.part_number ||
                            customerTrackerData?.part_number}
                    </p>
                    {customerTrackerData?.part_number ||
                        customerTrackerData?.part_id?.part_number
                        ? !isOnlyViewModal && (
                            <Wrapper
                                className="pir_add_modal"
                                style={{ backgroundColor: "#343a40" }}
                                justify="center"
                                onClick={() => {
                                    customerTrackerData.part_number = null;
                                    customerTrackerData.part_id = null;
                                    setRender((prev) => !prev);
                                }}
                            >
                                <Remove />
                            </Wrapper>
                        )
                        : !isOnlyViewModal && (
                            <Wrapper
                                className="pir_add_modal"
                                justify="center"
                                style={{ backgroundColor: "#495057" }}
                                onClick={() => {
                                    customerTrackerData.openAddPartModal = true;
                                    setModalCloseBtn(true);
                                    setRender((prev) => !prev);
                                }}
                            >
                                <Add />
                            </Wrapper>
                        )}
                </Wrapper>
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="problem_Defect">Problem/Defect :</label>
                <input
                    type="text"
                    id="problem_Defect"
                    name="problem_Defect"
                    defaultValue={customerTrackerData?.problem_Defect}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="rej_qty">Rej. Qty :</label>
                <input
                    type="number"
                    id="rej_qty"
                    name="rej_qty"
                    defaultValue={customerTrackerData?.rej_qty}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="root_cause">Root Cause :</label>
                <input
                    type="text"
                    id="root_cause"
                    name="root_cause"
                    defaultValue={customerTrackerData?.root_cause}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="corrective_action">Corrective Action :</label>
                <input
                    type="text"
                    id="corrective_action"
                    name="corrective_action"
                    defaultValue={customerTrackerData?.corrective_action}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label for="tdc">TDC :</label>
                <DatePicker
                    name="tdc"
                    format="dd/MM/yyyy"
                    InputProps={{
                        disableUnderline: true,
                    }}
                    style={{
                        borderLeft: "2px solid #ced4da",
                        paddingLeft: "0.5rem",
                    }}
                    value={customerTrackerData?.tdc}
                    readOnly={isOnlyViewModal}
                    onChange={(date) => {
                        customerTrackerData.tdc = date;
                        setRender((prev) => !prev);
                    }}
                />
            </DivStyled>

            <DivStyled num={1}>
                <label htmlFor="onsite_m1">Onsite Inspection M1:</label>
                <input
                    type="text"
                    id="onsite_m1"
                    name="onsite_m1"
                    defaultValue={customerTrackerData?.onsite_m1}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="onsite_m2">Onsite Inspection M2:</label>
                <input
                    type="text"
                    id="onsite_m2"
                    name="onsite_m2"
                    defaultValue={customerTrackerData?.onsite_m2}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="onsite_m3">Onsite Inspection M3:</label>
                <input
                    type="text"
                    id="onsite_m3"
                    name="onsite_m3"
                    defaultValue={customerTrackerData?.onsite_m3}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="monitoring_l1">
                    Effectiveness Of Capa Monitoring L1:
                </label>
                <input
                    type="text"
                    id="monitoring_l1"
                    name="monitoring_l1"
                    defaultValue={customerTrackerData?.monitoring_l1}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="monitoring_l2">
                    Effectiveness Of Capa Monitoring L2:
                </label>
                <input
                    type="text"
                    id="monitoring_l2"
                    name="monitoring_l2"
                    defaultValue={customerTrackerData?.monitoring_l2}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="monitoring_l3">
                    Effectiveness Of Capa Monitoring L3:
                </label>
                <input
                    type="text"
                    id="monitoring_l3"
                    name="monitoring_l3"
                    defaultValue={customerTrackerData?.monitoring_l3}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
            <DivStyled num={1}>
                <label htmlFor="qa_head_sign">Qa Head Sign:</label>
                <input
                    type="text"
                    id="qa_head_sign"
                    name="qa_head_sign"
                    defaultValue={customerTrackerData?.qa_head_sign}
                    onBlur={handleCustomerData}
                    readOnly={isOnlyViewModal}
                />
            </DivStyled>
        </section>
    );
};

export default ComplainList;
