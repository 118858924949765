import React, { useEffect, useState } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Input from "../../components/Control/Input";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
// import SaveIcon from "@material-ui/icons/Save";
// import ClearAllIcon from "@material-ui/icons/ClearAll";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";

const AddEditMachine = ({ setIsNewMachineModal, id }) => {
  const selectDepartment = [
    "None",
    "Cutting Section",
    "Hardening",
    "CNC Section",
    "VMC SECTION",
    "NEW BLOCK",
    "Cutting Section",
    "Grinding Section",
    "Drilling Section",
    "Hex punch",
    "Milling Section",
    "Thread Rolling Section",
    "Buffing Section",
    "Compressor room",
    "Generator room",
    "Final inspection",
  ];
  const [machineData, setMachineData] = useState({});

  const [DialogSubmit, confirmSubmit] = useConfirm(
    "MACHINE",
    `Are you sure you want to ${id ? "update" : "save"} this part?`
  );

  function handleMachineData(e) {
    setMachineData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/get_machineById/${id}`)
        .then((res) => setMachineData(res.data))
        .catch((err) => console.log(err));
    } else {
      setMachineData({
        date_orig: new Date(),
      });
    }
  }, [id]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/update_machine/${id}`, machineData)
        .then((res) => {
          setIsNewMachineModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/create/machine", machineData)
        .then((res) => {
          setIsNewMachineModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="root-admin">
      <form
        style={{
          margin: "1.8rem",
        }}
        onSubmit={(e) => onSubmit(e)}
      >
        <div>
          <div className="supplier-section">
            <section className="supplier-section-part">
              <Input
                type="text"
                name="machine_name"
                placeholder="Machine Name"
                required
                onChange={handleMachineData}
                value={machineData?.machine_name}
              />
              <Input
                type="text"
                name="machine_no"
                placeholder="Machine Id"
                onChange={handleMachineData}
                value={machineData.machine_no}
              />
              <FormControl
                style={{
                  width: "250px",
                  marginRight: "0.5rem",
                  marginTop: "0.6rem",
                  marginLeft: "0.6rem",
                }}
                variant="filled"
                className="select-field"
              >
                <InputLabel>Select Department</InputLabel>
                <Select
                  name="department"
                  key={machineData.department}
                  defaultValue={machineData.department}
                  onChange={handleMachineData}
                  className="spec-select-character"
                >
                  {selectDepartment.map((department, index) => {
                    return (
                      <MenuItem key={index} value={department}>
                        {department}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </section>

            <div
              className="btn_save"
              style={{
                position: "absolute",
                right: "150px",
                top: "9px",
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                color="white"
                size="large"
                style={{ color: "#e9e9e9" }}
                startIcon={<PublishIcon />}
              >
                {id ? "UPDATE" : "SUBMIT"}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <DialogSubmit isSubmitContent={false} />
    </div>
  );
};

export default AddEditMachine;
