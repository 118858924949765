export const Ultrasonic = [
  {
    check_point: "Check the Abnormal Sound of Machine.",
    check_by: "Ears",
    freq_of_main: "All Moving parts",
  },
  {
    check_point: "Check the Abnormal Smell of Machine.",
    check_by: "Nose",
    freq_of_main: "Electrical Panel, wiring",
  },
  {
    check_point: "Check the loose wiring of Machine.",
    check_by: "Visual",
    freq_of_main: "Electrical Panel",
  },
  {
    check_point: "Check the Oil / water Leakage of Machine.",
    check_by: "Visual",
    freq_of_main: "Tank, Vessels , Hoses",
  },
  {
    check_point: "Check the Abnormal vibration of Machine.",
    check_by: "Sensing",
    freq_of_main: "Machine Foundation, Equipments.",
  },
  {
    check_point: "Check the Abnormal Temperature of Machine.",
    check_by: "Sensing",
    freq_of_main: "Electrical Motors",
  },
];

export const Pipe_Bending = [
  {
    check_point: "Check the Abnormal Sound of Machine.",
    check_by: "Ears",
    freq_of_main: "All Moving parts",
  },
  {
    check_point: "Check the Abnormal Smell of Machine.",
    check_by: "Nose",
    freq_of_main: "Electrical Panel, wiring",
  },
  {
    check_point: "Check the loose wiring of Machine.",
    check_by: "Visual",
    freq_of_main: "Electrical Panel",
  },
  {
    check_point: "Check the Oil / water Leakage of Machine.",
    check_by: "Visual",
    freq_of_main: "Tank, Vessels , Hoses",
  },
  {
    check_point: "Check the Abnormal vibration of Machine.",
    check_by: "Sensing",
    freq_of_main: "Machine Foundation, Equipments.",
  },
  {
    check_point: "Check the Abnormal Temperature of Machine.",
    check_by: "Sensing",
    freq_of_main: "Electrical Motors",
  },
  {
    check_point:
      "Check  all sensors & limit switches for proper working and Firm mounting.",
    check_by: "Sensing / Visual",
    freq_of_main: "Machine",
  },
];

export const Leakage_Testing = [
  {
    check_point: "Check the Abnormal Sound of Machine.",
    check_by: "Ears",
    freq_of_main: "All Moving parts",
  },
  {
    check_point: "Check the Abnormal Smell of Machine.",
    check_by: "Nose",
    freq_of_main: "Electrical Panel, wiring",
  },
  {
    check_point: "Check the loose wiring of Machine.",
    check_by: "Visual",
    freq_of_main: "Electrical Panel",
  },
  {
    check_point: "Check the Oil / water Leakage of Machine.",
    check_by: "Visual",
    freq_of_main: "Tank, Vessels , Hoses",
  },
  {
    check_point: "Check the Abnormal vibration of Machine.",
    check_by: "Sensing",
    freq_of_main: "Machine Foundation, Equipments.",
  },
  {
    check_point: "Check the Abnormal Temperature of Machine.",
    check_by: "Sensing",
    freq_of_main: "Electrical Motors",
  },
  {
    check_point: "Check Pneumatic pressure leakage.",
    check_by: "Visual",
    freq_of_main: "FRL , Pneumatic pipe, connectors.",
  },
];

export const Flushing = [
  {
    check_point: "Check the Abnormal Sound of Machine.",
    check_by: "Ears",
    freq_of_main: "All Moving parts",
  },
  {
    check_point: "Check the Abnormal Smell of Machine.",
    check_by: "Nose",
    freq_of_main: "Electrical Panel, wiring",
  },
  {
    check_point: "Check the loose wiring of Machine.",
    check_by: "Visual",
    freq_of_main: "Electrical Panel",
  },
  {
    check_point: "Check the Oil / water Leakage of Machine.",
    check_by: "Visual",
    freq_of_main: "Tank, Vessels , Hoses",
  },
  {
    check_point: "Check the Abnormal vibration of Machine.",
    check_by: "Sensing",
    freq_of_main: "Machine Foundation, Equipments.",
  },
  {
    check_point: "Check the Abnormal Temperature of Machine.",
    check_by: "Sensing",
    freq_of_main: "Electrical Motors",
  },
  {
    check_point: "Check Pneumatic pressure leakage.",
    check_by: "Visual",
    freq_of_main: "FRL , Pneumatic pipe, connectors.",
  },
];
