import React, { useState } from "react";
import {
  Table,
  // TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  IconButton,
  Tooltip,
  TableContainer,
  InputAdornment,
  Paper,
  Button,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Modal from "../../../components/Control/Modal";
import SelectChecklist from "./SelectChecklist";
import axios from "axios";
import useConfirm from "../../../components/Control/useConfirm";
import useDebounce from "../../../components/Control/useDebounce";
import { Pagination } from "@material-ui/lab";
import { Add, Search } from "@material-ui/icons";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import formatDate from "../../../components/Control/formatDate";
import { useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { useQuery } from "../../../components/Control/useQuery";
import { PersitedDatePicker } from "../../../components";
import Loader from "../../../components/Control/Loader";
import Sync from "@material-ui/icons/Sync";
import { DatePicker } from "@material-ui/pickers";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";

const heading = {
  preventive: "PREVENTIVE",
  predictive: "PREDICTIVE",
};

function getCurrentStatus(status, dueDate) {
  let diff;
  if (dueDate) {
    let dueDateArr = dueDate.split("/");
    diff =
      new Date(`${dueDateArr[1]}/${dueDateArr[0]}/${dueDateArr[2]}`) -
      new Date();
  }
  if (status === "pending" && diff < 0) {
    return (
      <TableCell
        align="center"
        style={{ backgroundColor: "#e03131", color: "white" }}
      >
        MAINTENANCE OVERDUE
      </TableCell>
    );
  }

  if (status === "pending") {
    return (
      <TableCell align="center" style={{ backgroundColor: "#fcc419" }}>
        MAINTENANCE NOT STARTED
      </TableCell>
    );
  }

  if (status === "completed") {
    return (
      <TableCell
        align="center"
        style={{ backgroundColor: "#2f9e44", color: "white" }}
      >
        COMPLETED
      </TableCell>
    );
  }
  if (status === "started") {
    return (
      <TableCell align="center" style={{ backgroundColor: "#5c7cfa" }}>
        MAINTENANCE ONGOING
      </TableCell>
    );
  }
}

const ChecklistActionList = () => {
  const history = useHistory();

  const [isNewSelectChecklistModal, setIsNewSelectChecklistModal] =
    useState(false);
  const [checklistData, setChecklistData] = useState([]);
  const checkListCurrentID = useRef("");
  const query = useQuery();
  const documentType = query.get("documentType");

  const [render, setRender] = useState(false);
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState('');
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState();
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this checklist?",
  );
  const [DialogChange, confirmChange] = useConfirm(
    "Change Machine",
    "Are you sure you want to change machine?",
  );
  const fetchData = async () => {
    const res = await axios
      .get(`/api/getAllChecklist/${documentType}?page=${page}&search=${search}&date=${date}&status=${status}`)
      .catch((err) => {
        console.log(err);
      });
    setChecklistData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    isNewSelectChecklistModal,
    date,
    status
  ]);

  async function removeChecklist(id) {
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      await axios.delete(`/api/deleteChecklist/${id}`);
      setRender((prev) => !prev);
    }
  }

  const handleClearChecklistTable = async (machineid) => {
    const isClear = await confirmChange();
    if (isClear) {
      axios
        .get(`/api/getChecklistById/${machineid}`)
        .then((res) => {
          if (res.data) {
            let data = res.data;
            data.checkListMachineData = {
              Spot_Welding: [],
              Grinding: [],
              Bending_Machine_01: [],
              Induction_Brazing_Machine: [],
              NRV_Testing_Machine: [],
              Pera_Flange_Machine: [],
              Bending_Machine_02: [],
              Flushing_Machine: [],
              Pipe_Flairing_Machine: [],
              Flushing: [],
              Leakage_Testing: [],
              Pipe_Bending: [],
              Ultrasonic: [],
              Tig_Welding: [],
              Clamping: [],
              Ultrasonic_1: [],
              Oven: [],
              Farule_Fitting_Machine: [],
              Magna_Flux: [],
              Lazer_Marking: [],
              Air_Decay: [],
              Soco_Cutter_Machine: [],
              Crimping_Machine: [],
              Ss_Furnace: [],
              Cutting_Machine: [],
              Benjo_Press_Machine: [],
              Deburring_Machine: [],
              Oil_Flushing_Machine: [],
              Polishing_Machine: [],
              End_Forming_Machine: [],
              Pad_Printing: [],
              Centre_Lathe_Machine: [],
              Hydraullic_Press_1: [],
              Radial_Drill_Machine: [],
              Ultrasonic_2: [],
              Hydraullic_Press_2: [],
              Surface_Grinder: [],
              Blockage_Testing_Machine: [],
              Drill_Machine: [],
              Air_Leaking_Testing: [],
              T_Drill_Machine: [],
              High_Pressure_Leakage: [],
              Bust_Testing_Machine: [],
              ORing_Testing_Machine: [],
              Bending_Machine: [],
            };
            data.document_name = "";
            data.status = "pending";
            axios
              .put(`/api/updateChecklist/${machineid}`, data)
              .then((res) => {
                window.alert("updated successfully");
                setRender((prev) => !prev);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          ACTIVE {heading[documentType]} CHECKLISTS
        </Typography>
      </div>
      <TableContainer component={Paper}>
        <Wrapper style={{
          flex: "1", marginLeft: "1.2rem",
          marginTop: "1rem",
        }}>
          <Input
            placeholder="Search Machine No."
            variant="filled"
            style={{
              width: "1000px",
            }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <DatePicker
            inputVariant="filled"
            name="date"
            views={['year', 'month']}
            style={{ paddingLeft: "0.6rem" }}
            label={"Search By Month"}
            value={date}
            onChange={(newDate) => setDate(newDate)}
            format="dd/MM/yyyy"
            className="date-field"
          />
          <FormControl
            style={{
              marginRight: "0.5rem",
              width: "20rem",
              marginLeft: "1.2rem",
            }}
            variant="filled"
            className="select-field"
          >
            <InputLabel>Status </InputLabel>
            <Select
              name="status"
              defaultValue={status}
              onChange={(e) => setStatus(e.target.value)}
              placeholder="status"
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="pending">MAINTENANCE OVERDUE / NOT STARTED</MenuItem>
              <MenuItem value="completed">COMPLETED</MenuItem>
              <MenuItem value="started">MAINTENANCE ONGOING</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <Table size="small">
          <TableHead>
            <TableRow style={{ padding: "6px" }}>
              {[
                "#",
                "Machine Name",
                "Machine No.",
                "Area",
                "Maintenance Due Date",
                "Plan Month",
                "Last Maintenance Date",
                "Status",
                "Action",
              ].map((item, index) => (
                <TableCell
                  key={index}
                  style={{ fontWeight: "bold" }}
                  align="center"
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {checklistData.map((item, index) => (
              <TableRow>
                <TableCell align="center">
                  {" "}
                  {perPage * (page - 1) + index + 1}
                </TableCell>
                <TableCell align="center">{item.machine_name}</TableCell>
                <TableCell align="center">{item.machine_no}</TableCell>
                <TableCell align="center">{item.area}</TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {item.preventive_time}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ textTransform: "capitalize" }}
                >
                  {item.preventive_key}
                </TableCell>
                <TableCell align="center">
                  {formatDate(item.last_maintenance_date)}
                </TableCell>
                {getCurrentStatus(item.status, item.preventive_time)}
                <TableCell
                  align="center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.document_name ? (
                    <Tooltip title="Change Machine" arrow>
                      <IconButton
                        size="small"
                        style={{
                          marginRight: ".5rem",
                        }}
                        onClick={(e) => {
                          handleClearChecklistTable(item._id);
                        }}
                      >
                        <SyncAltIcon
                          style={{ fontSize: "1.4rem", color: "#f03e3e" }}
                        />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <div
                      style={{
                        height: "28.4px",
                        width: "28.4px",
                        marginRight: ".5rem",
                      }}
                    ></div>
                  )}
                  <Tooltip title="View Checklist" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: ".5rem",
                      }}
                      onClick={() => {
                        if (item.document_name) {
                          history.push(
                            `/maintenanceForCheckedMachineForm/${item._id}/${item.document_name}?isView=true`,
                          );
                        }
                      }}
                    >
                      <VisibilityIcon
                        style={{ fontSize: "1.4rem", color: "#003566" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Checklist" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: ".5rem",
                      }}
                      onClick={() => {
                        if (item.document_name) {
                          history.push(
                            `/maintenanceForCheckedMachineForm/${item._id}/${item.document_name}?isView=false`,
                          );
                        } else {
                          checkListCurrentID.current = item._id;
                          setIsNewSelectChecklistModal(true);
                        }
                      }}
                    >
                      <Add style={{ fontSize: "1.4rem", color: "black" }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "4rem",
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, number) => setPage(number)}
          />
        </div>
      </TableContainer>
      <Modal
        openPopup={isNewSelectChecklistModal}
        title="Select Checklist"
        closeModal={(e) => setIsNewSelectChecklistModal(false)}
      >
        <SelectChecklist checkListCurrentID={checkListCurrentID.current} />
      </Modal>
      <DialogDelete />
      <DialogChange />
    </>
  );
};

export default ChecklistActionList;
