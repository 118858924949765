import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import clsx from "clsx";
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import HomeIcon from "@material-ui/icons/Home";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { useAuth } from "../../context/GlobalContext";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import HelpIcon from "@material-ui/icons/Help";
import { Wrapper } from "../Control/Flexbox";
import useConfirm from "../Control/useConfirm";
import { images, textInputs } from "../../constants/constants";
import CommonNotification from "./CommonNotification";
import ChecklistNotification from "./ChecklistNotification";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import WhatsApp from "@material-ui/icons/WhatsApp";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    "& .MuiAppBar-colorPrimary": {
      backgroundColor: "#161a1d",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  display: "none",
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
  noti: {
    "& .MuiPaper-rounded": {},
    "& .MuiMenu-paper": {
      width: "fit-content",
      top: "50px !important",
    },
  },
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
}));

function Header() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [isHelp, setIsHelp] = useState(false);
  const { state, logout } = useAuth();
  const userType = state.user.userType;
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [Dialog, confirmLogoutUser] = useConfirm(
    "LOG OUT",
    "Are you sure you want to log out?",
  );
  const [DialogHome, confirmDialogHome] = useConfirm(
    "HOME",
    "Are you sure you want to go to homepage?",
  );

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpenDrawer(open);
  };

  const logoutUser = async () => {
    const ans = await confirmLogoutUser();
    if (ans) {
      logout();
      history.push("/");
    }
  };

  const backToHome = async () => {
    const newPath = location.pathname.split("/");
    if (location.pathname !== "/") {
      const ans = await confirmDialogHome();
      if (ans) {
        history.push("/");
      }
    }
  };

  const list = (anchor) => (
    <>
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })}
        role="presentation"
        onClick={toggleDrawer(false)}
        style={{ height: "90vh" }}
        onKeyDown={toggleDrawer(false)}
      >
        <Wrapper
          justify="center"
          style={{ backgroundColor: "#171b1e", height: "65px" }}
        >
          <img src={images.arcLogo} alt={textInputs.companyName} height={50} />
        </Wrapper>
        <Wrapper
          style={{ margin: "1rem", marginBottom: "1rem", marginTop: "1.5rem" }}
          justify="center"
        >
          <Avatar
            src={state.user?.profile}
            className="avatar"
            style={{ height: "170px", width: "170px" }}
          />
        </Wrapper>
        <List>
          <ListItem button onClick={logoutUser}>
            <ListItemIcon>
              <ExitToAppIcon style={{ color: "maroon" }} />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
        </List>
      </div>

      <div
        style={{
          textAlign: "center",
          width: "300px",
          width: "100%",
          marginBottom: "2rem",
          bottom: "0",
        }}
      >
        <div>
          <img
            width="250px"
            height="250"
            src={images.mushinlabFullLogo}
            alt="logo2"
          />
        </div>
        <div style={{ marginTop: "-2rem" }}>
          <a
            style={{ color: "black" }}
            href={"https://www.mushinlabs.com"}
            target="_blank"
            rel="noreferrer"
          >
            www.mushinlabs.com
          </a>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className={classes.grow}>
        <AppBar position="static" elevation="0">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <div style={{ marginRight: "16px", marginTop: "-4px" }}>
              <img
                width="65px"
                height="23px"
                src={images.mushinlabHalfLogo}
                alt="logo"
              />
            </div>

            <div
              noWrap
              onClick={backToHome}
              style={{
                fontSize: "1.5rem",
                fontWeight: 100,
                fontFamily: "Nunito",
                letterSpacing: "0.1em",
                cursor: "pointer",
              }}
            >
              {textInputs.companyName}
            </div>
            <div className={classes.grow} />
            <div>
              <Wrapper>
                <p
                  style={{
                    fontFamily: "'Nunito',sans-serif",
                    margin: 0,
                    textTransform: "uppercase",
                    fontSize: "1.3rem",
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>Welcome,</span>{" "}
                  {state.user?.name
                    ? state.user.name
                    : userType === 2
                    ? "ADMIN"
                    : "SUPERVISOR"}
                </p>
                <IconButton
                  aria-label="show notifications"
                  color="inherit"
                  style={{ marginLeft: "0.6rem" }}
                  onClick={(e) => setIsHelp(e.currentTarget)}
                >
                  <HelpIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={isHelp}
                  keepMounted
                  open={Boolean(isHelp)}
                  onClose={() => setIsHelp(false)}
                  className={classes.noti}
                >
                  <MenuItem>Contact Number: (+91) 97170 86212</MenuItem>
                  <MenuItem>
                    Email ID: rachit.srivastava@mushinlabs.com
                  </MenuItem>
                </Menu>
              </Wrapper>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <div>
        <SwipeableDrawer
          anchor={"left"}
          open={isOpenDrawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          style={{
            overflowX: "hidden",
            root: {
              overflowY: "hidden",
            },
          }}
        >
          {list("left")}
        </SwipeableDrawer>
      </div>
      <Dialog isSubmitContent={false} />
      <DialogHome isSubmitContent={false} />
    </>
  );
}
export default Header;
