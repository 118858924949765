import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { images, textInputs } from "../../constants/constants";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { ButtonStyled, TableContainerStyled } from "../DailyProduction/styles";
import Input from "../../components/Control/Input";
// import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import AddEditMachine from "./AddEditMachine";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
// import { useAuth } from "../../context/GlobalContext";
import useConfirm from "../../components/Control/useConfirm";
import "../style.css";
import formatDate from "../../components/Control/formatDate";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import TableToExcel from "@dayalk/table-to-excel";

const MachineList = () => {
  const [data, setData] = useState([]);
  const [isNewMachineModal, setIsNewMachineModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  // const { state } = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("machine_name");
  const [machineId, setMachineId] = useState(null);

  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this machine ?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(`/api/get/machine?page=${page}&sortBy=${filter}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    filter,
    // duplicateOpenModal,
    isNewMachineModal,
  ]);

  async function deleteMachine(id) {
    console.log(id, "in delte");
    const ans = await confirmDelete();
    if (ans) {
      await axios
        .delete(`/api/delete_machine/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          MACHINE LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setMachineId(null);
                setIsNewMachineModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Machine
            </Button>
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingLeft: "4px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("machinetable"),
                      {
                        name: "Machine List Report.xlsx",
                        sheet: {
                          name: "Machine List Report",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search Machine Name"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="part_type"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="machine_name">A - Z</MenuItem>
                <MenuItem value="department">Department</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <TableContainerStyled>
            <Table
              size="small"
              data-default-wrap="true"
              id="machinetable"
              data-cols-width="15, 15, 15, 20, 15, 18, 15"
            >
              <thead style={{ display: "none" }}>
                <tr style={{ height: "70px" }}>
                  <th
                    colSpan="10"
                    align="left"
                    style={{
                      border: "1px solid black",
                      fontSize: "1.6rem",
                      textAlign: "center",
                      position: "relative",
                    }}
                  >
                    <CenteredLogoImg>
                      <img
                        src={images.arcLogo}
                        alt={textInputs.companyName}
                        height="46"
                        width="120"
                      />
                      <span>{textInputs.companyName}</span>
                    </CenteredLogoImg>
                  </th>
                  <th
                    colSpan={2}
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    DOC. NO:- ARC/F/QAD/101
                  </th>
                </tr>
                <tr>
                  <th
                    colSpan="10"
                    align="center"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: 600,
                      backgroundColor: "#1d1d1d",
                      color: "white",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    MACHINE LIST
                  </th>
                  <th colSpan={2} style={{ border: "1px solid black" }}>
                    REV NO./DATE :- 01/29.09.2022
                  </th>
                </tr>
              </thead>
              <TableHead>
                <TableRow style={{ padding: "6px" }}>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    #
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    Machine Name{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    ID No.
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    Department
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    Last Maintenance Done Date{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    Maintenance Due Date{" "}
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {data && (
                <TableBody>
                  {data.map((item, index) => (
                    <React.Fragment key={item._id}>
                      <TableRow>
                        <TableCell
                          align="center"
                          //  width={10}
                        >
                          {perPage * (page - 1) + index + 1}
                        </TableCell>
                        <TableCell align="center">
                          {item.machine_name}
                        </TableCell>
                        <TableCell align="center">{item.machine_no}</TableCell>
                        <TableCell align="center">{item.department}</TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {item?.last_maintenance_date}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textTransform: "capitalize" }}
                        >
                          {item?.maintenance_due_date}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap", width: "220px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              minWidth: "250px",
                              width: "220px",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title="Edit Machine" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: ".5rem",
                                }}
                                onClick={() => {
                                  setMachineId(item._id);
                                  setIsNewMachineModal(true);
                                }}
                              >
                                <EditIcon
                                  style={{ fontSize: "1.4rem", color: "black" }}
                                />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Delete Machine" arrow>
                              <IconButton
                                size="small"
                                style={{
                                  marginRight: "0.8rem",
                                }}
                                onClick={() => {
                                  deleteMachine(item._id);
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    fontSize: "1.4rem",
                                    color: "#c80202",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainerStyled>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={machineId ? "update machine" : "create new machine"}
          openPopup={isNewMachineModal}
          closeModal={() => {
            setIsNewMachineModal(false);
            setMachineId(null);
          }}
          backgroundColor="white"
        >
          <AddEditMachine id={machineId} {...{ setIsNewMachineModal }} />
        </Modal>
      </main>
      <DialogDelete />
    </>
  );
};

export default MachineList;
