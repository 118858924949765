import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import lodash from "lodash";

import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import { useAuth } from "../../../context/GlobalContext";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import useConfirm from "../../../components/Control/useConfirm";
import { useQuery } from "../../../components/Control/useQuery";
import SyncIcon from "@material-ui/icons/Sync";
import Loader from "../../../components/Control/Loader";
import { ButtonStyled, TableContainerStyled, Table } from "./styles";
import { DatePicker } from "@material-ui/pickers";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const maintenanceObj = {
  april: {
    p: false,
    a: [],
  },
  may: {
    p: false,
    a: [],
  },
  june: {
    p: false,
    a: [],
  },
  july: {
    p: false,
    a: [],
  },
  august: {
    p: false,
    a: [],
  },
  september: {
    p: false,
    a: [],
  },
  october: {
    p: false,
    a: [],
  },
  november: {
    p: false,
    a: [],
  },
  december: {
    p: false,
    a: [],
  },
  january: {
    p: false,
    a: [],
  },
  february: {
    p: false,
    a: [],
  },
  march: {
    p: false,
    a: [],
  },
};
const heading = {
  preventive: "PREVENTIVE",
  predictive: "PREDICTIVE",
};
const stickStylesRow = {
  position: "sticky",
  width: "100%",
  top: "-.5rem",
  border: "1px solid black",
  zIndex: "2",
  height: "50px",
  backgroundColor: "#4F81BD",
};

const stickStylesColumnHeader = {
  position: "sticky",
  border: "1px solid black",
  zIndex: "1",
  height: "50px",
  backgroundColor: "#4F81BD",
  // color: "#fff",
};

const stickStylesColumnBody = {
  position: "sticky",
  border: "1px solid black",
  zIndex: "1",
  backgroundColor: "#ffec99",
  // color: '#fff',
};

const CreateEditPreventiveMaintenance = () => {
  const classes = dropdownStyles();
  const history = useHistory();
  const { partId, id } = useParams();
  const { state } = useAuth();
  const [render, setRender] = useState(false);
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const [isLoading, setIsloading] = useState(false);
  const documentType = query.get("documentType");

  const [preventiveMaintenance, setPreventiveMaintenance] = useState({
    maintenance_list: [],
  });
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogAddTask, confirmDialogAddTask] = useConfirm(
    "Add Task",
    "Are you sure you want to add a Task?"
  );
  const [DialogRemoveTask, confirmDialogRemoveTask] = useConfirm(
    "Remove Task",
    "Are you sure you want to remove a Task?"
  );
  const [DialogSyncMachine, confirmDialogSyncMachine] = useConfirm(
    "Sync Machine",
    "Are you sure you want to Sync new Machines?"
  );
  const [DialogUpdate, confirmDialogUpdate] = useConfirm(
    "Update Plan",
    "Are you sure you want to update this plan?"
  );
  const [DialogSave, confirmDialogSave] = useConfirm(
    "Save Plan",
    "Are you sure you want to save this plan?"
  );

  useEffect(() => {
    axios
      .get(`/api/getPreventiveById/${partId}`)
      .then((res) => setPreventiveMaintenance(res.data))
      .catch((err) => console.log(err));
  }, []);

  const handleDoubleClick = async (date, month, idx) => {
    if (date) {
      let ans = await confirmDialogAddTask();
      if (ans) {
        preventiveMaintenance.maintenance_list[idx][month].p = true;
        preventiveMaintenance.maintenance_list[idx][month].created_date = date;
        setRender((prev) => !prev);
      }
    } else {
      let ans = await confirmDialogRemoveTask();
      if (ans) {
        preventiveMaintenance.maintenance_list[idx][month].p = false;
        preventiveMaintenance.maintenance_list[idx][month].created_date = date;
        preventiveMaintenance.maintenance_list[idx][month].isTask = false;
        delete preventiveMaintenance.maintenance_list[idx][month].doneDate;
        setRender((prev) => !prev);
      }
    }
  };

  const handleUpdate = async () => {
    const ans = await confirmDialogUpdate();
    if (!ans) return;
    setIsloading(true);
    if (ans) {
      axios
        .put(`/api/updatePreventive/${partId}`, preventiveMaintenance)
        .then((res) => window.alert("success"))
        .then(() => {
          setIsloading(false);
          history.goBack();
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
    }
  };

  const handleSyncMachines = async () => {
    let ans = await confirmDialogSyncMachine();
    if (!ans) return;

    setIsloading(true);
    let machines_id = {};
    preventiveMaintenance.maintenance_list.forEach((machine) => {
      let machine_id;
      if (machine.machine_name) {
        machine_id = machine.machine_id;
      } else {
        machine_id = machine.machine_id?._id;
      }
      machines_id[machine_id] = machine_id;
    });
    axios
      .get(`/api/get/machine`)
      .then((res) => {
        const timer = setTimeout(() => {
          setIsloading(false);
          window.alert("successfully updated");
        }, 1000);
        const response = res.data;
        response.forEach((item) => {
          if (!(item._id in machines_id)) {
            preventiveMaintenance?.maintenance_list?.push({
              machine_no: item.machine_no,
              machine_name: item.machine_name,
              area: item.area,
              machine_id: item._id,
              ...lodash.cloneDeep(maintenanceObj),
            });
          }
        });
        setRender((prev) => !prev);
      })
      .catch((err) => {
        const timer = setTimeout(() => {
          setIsloading(false);
        }, 1000);
        console.log(err);
      });
  };

  function MouseOver(event) {
    event.target.style.transform = "scale(1.2)";
    event.target.style.transition = "200ms";
    event.target.style.border = "1px solid #ced4da";
    event.target.style.borderRadius = "5px";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.2)";
  }
  function MouseOut(event) {
    event.target.style.transform = "scale(1)";
    event.target.style.border = "none";
    event.target.style.boxShadow = "none";
    event.target.style.transition = "200ms";
  }
  function MouseDown(event) {
    event.target.style.transform = "scale(1.1)";
    event.target.style.transition = "300ms";
    event.target.style.border = "1px solid #868e96";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.3)";
  }

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 60;
    } else {
      document.getElementById("machinetable").scrollLeft -= 60;
    }
  };

  return (
    <div
      style={{
        paddingBottom: "4rem",
        position: "relative",
      }}
    >
      <ButtonStyled
        left
        onClick={(e) => {
          handleScroll("left");
        }}
      >
        <div>
          <ArrowBackIosIcon style={{ color: "#fff" }} />
        </div>
      </ButtonStyled>
      <ButtonStyled
        right
        onClick={(e) => {
          handleScroll("right");
        }}
      >
        <div>
          <ArrowForwardIosIcon style={{ color: "#fff" }} />
        </div>
      </ButtonStyled>
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: "100",
            top: "50%",
            right: "50%",
            transform: "translate(50%,-50%)",
          }}
        >
          {<Loader height={"100vh"} />}
        </div>
      ) : (
        ""
      )}
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          ANNUAL {heading[DocumentType]} MAINTENANCE SCHEDULE REPORT
        </Typography>
        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#1864ab",
                marginRight: "0.5rem",
              }}
              startIcon={<SyncIcon />}
              onClick={() => {
                handleSyncMachines(true);
              }}
            >
              Synch Machines
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#064a29",
                marginRight: "0.5rem",
              }}
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                handleUpdate();
              }}
            >
              Update
            </Button>
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>
      <TableContainerStyled id="machinetable">
        <Table>
          <thead>
            <tr data-height="80" style={{ height: "70px" }}>
              <th
                colSpan="15"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  position: "relative",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="200"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                colSpan={3}
                style={{
                  border: "1px solid black",
                }}
              >
                Doc.No.-FM/XLI/MT/008
              </th>
            </tr>

            <tr>
              <th
                colSpan="15"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="18"
                data-a-h="center"
                data-f-bold="true"
                data-fill-color="#1d1d1d"
                data-f-color="FFFFFF"
              >
                ANNUAL PREVENTIVE MAINTENANCE SCHEDULE
              </th>
              <th
                colSpan={3}
                style={{ bo5er: "1px solid black" }}
                data-f-sz="10"
              >
                REV. NO.:- 00/ November 2018
              </th>
            </tr>
            <tr>
              <th colSpan="4">DUE</th>
              <td colSpan="2" style={{ width: "5rem" }}>
                <span
                  style={{
                    fontSize: "2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  ⨂
                </span>
              </td>
              <th style={{ textAlign: "center" }} colSpan={2}>
                DONE
              </th>
              <th style={{ background: "#51cf66" }}></th>
              <th colSpan={2} style={{ textAlign: "center" }}>
                Reschedule
              </th>
              <th style={{ background: "#343a40" }}></th>
              <th style={{ textAlign: "center" }} colSpan={2}>
                Annual Basis
              </th>
              <td colSpan="4"></td>
            </tr>
            <tr>
              <th colSpan="2">YEAR:</th>
              <th colSpan="15">
                {getYearRange(preventiveMaintenance.date_range)}
              </th>
            </tr>
          </thead>
          <tr style={{ ...stickStylesRow }}>
            {[
              "Sr. No.",
              "Machine No.",
              "M/C Name",
              "Location",
              "Status",
              "APR",
              "MAY",
              "JUN",
              "JUL",
              "AUG",
              "SEP",
              "OCT",
              "NOV",
              "DEC",
              "JAN",
              "FEB",
              "MAR",
              "Remarks",
            ].map((el, index) => (
              <th
                rowSpan={2}
                align="center"
                style={{
                  textAlign: "center",
                  ...(index === 0 && {
                    ...stickStylesColumnHeader,
                    left: 0,
                    width: "50px",
                    minWidth: "50px",
                  }),
                  ...(index === 1 && {
                    ...stickStylesColumnHeader,
                    left: 50,
                    width: "150px",
                    minWidth: "150px",
                  }),
                  ...(index === 2 && {
                    ...stickStylesColumnHeader,
                    left: 200,
                    width: "150px",
                    minWidth: "150px",
                  }),
                  ...(index === 3 && {
                    ...stickStylesColumnHeader,
                    left: 350,
                    width: "150px",
                    minWidth: "150px",
                  }),
                }}
              >
                {el}
              </th>
            ))}
          </tr>
          <tbody>
            {preventiveMaintenance &&
              preventiveMaintenance.maintenance_list.map((item, prvIndex) => (
                <>
                  <tr className="listDataCenter">
                    <td
                      rowSpan="2"
                      style={{
                        ...stickStylesColumnBody,
                        left: 0,
                        width: "50px",
                        minWidth: "50px",
                      }}
                    >
                      {prvIndex + 1}
                    </td>
                    <td
                      rowSpan="2"
                      style={{
                        ...stickStylesColumnBody,
                        left: 50,
                        width: "150px",
                        minWidth: "150px",
                      }}
                    >
                      {item.machine_no
                        ? item.machine_no
                        : item.machine_id?.machine_no}
                    </td>
                    <td
                      rowSpan="2"
                      style={{
                        ...stickStylesColumnBody,
                        left: 200,
                        width: "150px",
                        minWidth: "150px",
                      }}
                    >
                      {item.machine_name
                        ? item.machine_name
                        : item.machine_id?.machine_name}
                    </td>
                    <td
                      rowSpan={2}
                      style={{
                        ...stickStylesColumnBody,
                        left: 350,
                        width: "150px",
                        minWidth: "150px",
                      }}
                    >
                      {item.area ? item.area : item.machine_id?.area}
                    </td>
                    <td>P</td>
                    {[
                      "april",
                      "may",
                      "june",
                      "july",
                      "august",
                      "september",
                      "october",
                      "november",
                      "december",
                      "january",
                      "february",
                      "march",
                    ].map((month) => (
                      <td
                        style={{
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        <DatePicker
                          label="Date"
                          name="date_performance"
                          className="date-field"
                          inputVariant="filled"
                          style={{ width: "120px" }}
                          clearable
                          format="dd/MM/yyyy"
                          value={item[month].created_date || null}
                          onChange={(date) => {
                            handleDoubleClick(date, month, prvIndex);
                          }}
                        />
                      </td>
                    ))}
                    <td
                      contentEditable={true}
                      data-name="remarks"
                      onKeyUp={(e) => {
                        item[e.target.dataset.name] = e.target.innerText;
                      }}
                      style={{
                        border: "1px solid black",
                        backgroundColor: "#f9e0e0",
                      }}
                    >
                      <span>{item.remarks}</span>
                    </td>
                  </tr>
                  <tr className="listDataCenter">
                    <td>A</td>
                    {[
                      "april",
                      "may",
                      "june",
                      "july",
                      "august",
                      "september",
                      "october",
                      "november",
                      "december",
                      "january",
                      "february",
                      "march",
                    ].map((month) => (
                      <td
                        height="75px"
                        width="75px"
                        style={{
                          backgroundColor: item[month].doneDate && "#37b24d",
                          color: "white",
                          textTransform: "capitalize",
                        }}
                      >
                        {item[month].doneDate}
                      </td>
                    ))}
                    <td
                      contentEditable={true}
                      data-name="remarks"
                      onKeyUp={(e) => {
                        item[e.target.dataset.name] = e.target.innerText;
                      }}
                      style={{
                        border: "1px solid black",
                        backgroundColor: "#f9e0e0",
                        minWidth: "150px",
                      }}
                    >
                      <span>{item.remarks}</span>
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </Table>
      </TableContainerStyled>
      <DialogClose isSubmitContent={false} />
      <DialogAddTask isSubmitContent={false} />
      <DialogRemoveTask isSubmitContent={false} />
      <DialogSyncMachine isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </div>
  );
};

export default CreateEditPreventiveMaintenance;
