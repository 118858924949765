import mushinlabFullLogo from "../images/DP Mushin Labs.png";
import mushinlabHalfLogo from "../images/WHITE.png";

export const proxy_img = "https://proxy.aqua.mushinlabs.com/proxy/";

export const images = {
  mushinlabFullLogo: mushinlabFullLogo,
  mushinlabHalfLogo: mushinlabHalfLogo,
  arcLogo: `${proxy_img}https://mushin-labs.s3.ap-south-1.amazonaws.com/imperial-auto/logos/Logo.png`,
};

export const textInputs = {
  companyName: "IMPERIAL AUTO INDUSTRIES LIMITED",
};
