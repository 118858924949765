import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import { useParams, Link } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { Paper } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PlayForWorkIcon from "@material-ui/icons/PlayForWork";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";

import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import useDebounce from "../../components/Control/useDebounce";
import useConfirm from "../../components/Control/useConfirm";
import formatDate from "../../components/Control/formatDate";
import AddNewEmployeeModal from "./AddNewEmployeeModal";
import UploadInductionFileModal from "./UploadInductionFileModal";
import { useRef } from "react";
import { useQuery } from "../../components/Control/useQuery";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import TableToExcel from "@dayalk/table-to-excel";
import Visibility from "@material-ui/icons/Visibility";

const levelText = (text) => {
  if (!text) return;
  return text.replace("_", " ");
};

const CreateEditEducationTraining = () => {
  const query = useQuery();
  const empId = query.get("empId");
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("employee_name");
  const [addNewEmployeeModal, setAddNewEmployeeModal] = useState(false);
  const [uploadInductinFileModal, setUploadFileModal] = useState(false);
  const [employeeData, setEmployeeData] = useState("");
  const setEmployeeId = useRef("");
  const inductionFormFile = useRef("");
  const effectivenessFormFile = useRef("");
  const [DialogDelete, setDiaglogDelete] = useConfirm(
    "DRAFT PART",
    `Are you sure you want to UPLOAD this part?`
  );
  const [EmpDelete, setEmpDelete] = useConfirm(
    "EMPLOYEE DELETE",
    `Are you sure you want to delete this employee?`
  );
  const fetchEmployeeData = () => {
    axios
      .get(
        `/api/getAllEmployee/data/?page=${page}&sortBy=${filter}&search=${search}&perPage=10&empId=${empId}`
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setEmployeeData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };
  useDebounce(fetchEmployeeData, 600, [
    page,
    search,
    render,
    filter,
    addNewEmployeeModal,
    uploadInductinFileModal,
  ]);

  const handleUploadDeletion = async (id, formType) => {
    const ans = await setDiaglogDelete();
    if (!ans) {
      return;
    }

    await axios
      .put(`/api/updateEmployee/data/${id}`, {
        ...(formType === "inductionform" && {
          induction_form_file: [],
          inductionForm_upload_date: "",
        }),
        ...(formType === "testform" && {
          effectiveness_test_file: [],
          effectivenessTest_uploadDate: "",
        }),
      })
      .then((res) => {
        window.alert("success");
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async (id) => {
    const ans = await setEmpDelete();
    if (!ans) {
      return;
    }
    axios
      .delete(`/api/deleteEmployee/${id}`)
      .then((res) => {
        window.alert("success");
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  const visibilityToolTip = (item) => {
    return (
      <Link to={`/employeeFileList/${item._id}/effectiveness_test_file`}>
        <Tooltip title="Upload Form" arrow>
          <IconButton size="small">
            <VisibilityIcon
              style={{
                fontSize: "1.2rem",
                color: "#1864ab",
              }}
            />
          </IconButton>
        </Tooltip>
      </Link>
    );
  };

  const blockToolTip = (item) => {
    return (
      <Tooltip title="block" arrow>
        <IconButton size="small">
          <BlockIcon
            style={{
              fontSize: "1.2rem",
              color: "#c92a2a",
              // marginRight: "0.5rem",
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const addToolTip = (id, formType) => {
    return (
      <Tooltip title="Upload Form" arrow>
        <IconButton
          size="small"
          onClick={() => {
            setEmployeeId.current = id;
            inductionFormFile.current = formType === "inductionform";
            effectivenessFormFile.current = formType === "testform";
            setUploadFileModal(true);
          }}
        >
          <AddIcon
            style={{
              fontSize: "1.5rem",
              color: "#c92a2a",
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const downloadFromTooltip = (url, currentLevel) => {
    return (
      <Tooltip title="Download Form" arrow>
        <IconButton
          size="small"
          onClick={() => {
            window.open(`${url}/${currentLevel}`, "_blank");
          }}
        >
          <PlayForWorkIcon
            style={{
              fontSize: "1.5rem",
              color: url ? "#2f9e44" : "#c92a2a",
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const getCurrentStatus = (status) => {
    if (status === "induction_ongoing") {
      return (
        <TableCell
          align="center"
          style={{
            backgroundColor: "#fcc419",
            color: "#343a40",
            minWidth: "205px",
          }}
        >
          INDUCTION ONGOING
        </TableCell>
      );
    }

    if (status === "effectiveness_ongoing") {
      return (
        <TableCell
          align="center"
          style={{
            backgroundColor: "#5c7cfa",
            color: "#f8f9fa",
            minWidth: "205px",
          }}
        >
          EFFECTIVENESS ONGOING
        </TableCell>
      );
    }

    if (status === "completed") {
      return (
        <TableCell
          align="center"
          style={{ backgroundColor: "#2f9e44", color: "white" }}
        >
          INDUCTION COMPLETED
        </TableCell>
      );
    }
  };

  const deleteTooltip = (id, formType) => {
    return (
      <Tooltip title="Delete" arrow>
        <IconButton
          size="small"
          onClick={() => handleUploadDeletion(id, formType)}
        >
          <ClearIcon
            style={{
              fontSize: "1.4rem",
              color: "red",
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          EMPLOYEE LIST
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setAddNewEmployeeModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Employee
            </Button>
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("machinetable"),
                      {
                        name: "Employee List Report.xlsx",
                        sheet: {
                          name: "Employee List",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.4} />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        <Wrapper>
          <div style={{ flex: "0.8" }}>
            <Input
              placeholder="Search Employee Name"
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{
              marginRight: "1.5rem",
              marginTop: "0.8rem",
              flex: "0.2",
            }}
          >
            <InputLabel>Filter</InputLabel>
            <Select
              name="part_type"
              onChange={(e) => setFilter(e.target.value)}
              defaultValue={filter}
            >
              <MenuItem value="createdAt">A - Z</MenuItem>
              <MenuItem value="employee_name">Employee Name</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <TableContainer component={Paper}>
          <Table
            size="small"
            data-default-wrap="true"
            id="machinetable"
            data-cols-width="15, 15, 15, 15, 15, 15, 20, 20, 20"
          >
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                {[
                  "S.no.",
                  "Employee Name",
                  "Designation",
                  "Qualification",
                  "Joining Date",
                  "Exp.years",
                  "Department",
                  "Current Level",
                  // "Induction Form",
                  // "Effectiveness Form",
                  // "Status",
                  "Action",
                ].map((item, index) => (
                  <TableCell
                    key={index}
                    style={{ fontWeight: "bold" }}
                    align="center"
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeData &&
                employeeData.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center">
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">{item.employee_name}</TableCell>
                      <TableCell align="center">{item.designation}</TableCell>
                      <TableCell align="center">{item.qualification}</TableCell>
                      <TableCell align="center">
                        {formatDate(item.data_of_joining)}
                      </TableCell>
                      <TableCell align="center">{item.exp_years}</TableCell>
                      <TableCell align="center">
                        {item?.area_of_work?.department_name}
                      </TableCell>
                      <TableCell align="center">
                        {levelText(item?.current_level)}
                      </TableCell>

                      {/* <TableCell align="center">
                        <Tooltip title="Download Form" arrow>
                          <IconButton
                            size="small"
                            onClick={() => {
                              window.open(item?.area_of_work_url, "_blank");
                            }}
                          >
                            <PlayForWorkIcon
                              style={{
                                fontSize: "1.5rem",
                                color: item?.area_of_work_url
                                  ? "#2f9e44"
                                  : "#c92a2a",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell> */}
                      <TableCell align="center">
                        <Tooltip title="Download Form" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: "0.5rem",
                            }}
                            onClick={() => {
                              window.open(item?.photo, "_blank");
                            }}
                          >
                            <Visibility
                              style={{
                                fontSize: "1.5rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: "0.5rem",
                            }}
                            onClick={() => {
                              setEmployeeId.current = item._id;
                              setAddNewEmployeeModal(true);
                            }}
                          >
                            <EditIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete" arrow>
                          <IconButton
                            size="small"
                            onClick={() => handleDelete(item._id)}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "red",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>

        <Modal
          title={"create new employee"}
          openPopup={addNewEmployeeModal}
          closeModal={() => {
            setAddNewEmployeeModal(false);
            setEmployeeId.current = null;
          }}
          backgroundColor="white"
        >
          <AddNewEmployeeModal
            {...{
              setAddNewEmployeeModal,
              setEmployeeId,
            }}
            id={setEmployeeId.current}
          />
        </Modal>

        <Modal
          title={"upload"}
          openPopup={uploadInductinFileModal}
          closeModal={() => {
            setUploadFileModal(false);
            setEmployeeId.current = null;
          }}
          backgroundColor="white"
        >
          <UploadInductionFileModal
            {...{ setUploadFileModal, setEmployeeId }}
            id={setEmployeeId.current}
            effectivenessFormFile={effectivenessFormFile.current}
            inductionFormFile={inductionFormFile.current}
          />
        </Modal>
        <DialogDelete isSubmitContent={false} />
        <EmpDelete isSubmitContent={false} />
      </main>
    </>
  );
};

export default CreateEditEducationTraining;
