import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
//icons
import TuneIcon from "@material-ui/icons/Tune";
import ListIcon from "@material-ui/icons/List";
// end icons

const Clause15 = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        Adequate Testing Facility
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        {/* <div>
          <Link to="/masterProcedureList?category=clause-15" className="panel">
             <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Master <span className="card-btn-span">Procedure</span>
          </p>
        </div> */}
        {/* <div> */}
        {/*   <Link to="/instrumentsList" className="panel"> */}
        {/*     <TuneIcon style={{ fontSize: "3rem", color: "#003566" }} /> */}
        {/*   </Link> */}
        {/*   <p className="card-btn-para"> */}
        {/*     Master List of */}
        {/*     <span className="card-btn-span">Instruments</span> */}
        {/*   </p> */}
        {/* </div> */}
      </main>
    </>
  );
};

export default Clause15;
