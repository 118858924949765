import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import { Add, Remove } from "@material-ui/icons";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { useAuth } from "../../context/GlobalContext";
import { Wrapper } from "../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import ForMTable from "./ForMTable";
import SelectPart from "./SelectPart";
import Modal from "../../components/Control/Modal";
import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../components/Control/useConfirm";
import { ButtonStyled, TableContainerStyled } from "./styles";
import moment from "moment";

const breakdownReasons = [
  "SETUP",
  "M/C",
  "MLD",
  "MAN",
  "N/P",
  "TRIAL",
  "PWR",
  "MAINT",
  "MAT",
  "OTHER",
];

const cellStyle = {
  border: "1px solid black",
  textAlign: "center",
  textTransform: "uppercase",
};

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 200px;
        border: none;
        height: 50px;
        text-align:center;
    }

`;

const ControlledInput = ({ onUpdate, disable, defaultvalue }) => {
  const [value, setState] = useState(defaultvalue);

  useEffect(() => {
    if (disable) {
      setState("");
    }
  }, [disable]);

  const handleChange = (e) => {
    setState(e.target.value);
    onUpdate(e.target.value);
  };

  return (
    <input
      className="input"
      value={value}
      disabled={disable}
      onChange={handleChange}
    />
  );
};

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

function calculateWorkingHour(time1, time2) {
  if (!time1 && !time2) return "";
  if (new Date(time2).getTime() / 1000 <= new Date(time1).getTime() / 1000) {
    time2 = new Date(time2).setDate(new Date(time2).getDate() + 1);
  }
  const msInHour = 1000 * 60 * 60;
  let workingHour = (new Date(time2) - new Date(time1)) / msInHour;
  if (isNaN(workingHour)) {
    return "";
  }
  let timeArr = workingHour.toString().split(".");
  let hour = timeArr[0];
  let min = 0;
  if (timeArr[1]) {
    min = `${Math.ceil((+timeArr[1].slice(0, 2) / 100) * 60)}`.padStart(2, 0);
  }
  if (min == 60) {
    min = 0;
  }
  return `${hour} hr ${min} min`;
}

function calculateWorkingEff(time1, time2, cycle_time, producedQty) {
  if (new Date(time2).getTime() / 1000 <= new Date(time1).getTime() / 1000) {
    time2 = new Date(time2).setDate(new Date(time2).getDate() + 1);
  }

  let workingHour =
    Math.abs(new Date(time2).getTime() - new Date(time1).getTime()) / 3600000;
  if (isNaN(workingHour)) return "";
  const idealQty = (workingHour * 3600) / +cycle_time;
  const workEff = (producedQty / idealQty) * 100;
  return workEff.toFixed(2);
}

const DailyProductionTable = () => {
  const classes = dropdownStyles();
  const { state } = useAuth();
  const { id } = useParams();
  const [is4mModal, setIs4mModal] = useState(false);
  const [isSelectPartModal, selectSelectPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [partDetails, setPartDetails] = useState([]);
  const index4m = useRef(0);
  const documentTypeRef = useRef(null);
  const indexSelectPart = useRef(0);
  const history = useHistory();
  const [operation, setOperation] = useState(
    new Array(25).fill().map((_) => [])
  );
  const [failures, setFailures] = useState(new Array(25).fill().map((_) => []));
  const [dailyProductionData, setDailyProductionData] = useState({
    dailyProduction_date: new Date(),
    processes: new Array(25).fill().map((_) => ({
      breakdown_attended: "",
      breakdown_qty: "",
      breakdown_reason: "",
      breakdown_remarks: "",
      breakdown_work_done: "",
      spare_parts: "",
    })),
  });

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );

  useEffect(() => {
    axios
      .get(`/api/getPartDetails`)
      .then((res) => {
        setPartDetails(res.data?.results);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAllOperation = (partId, index) => {
    axios
      .get(`/api/getOperationDetails/${partId}`)
      .then((res) => {
        operation[index] = res.data;
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
        setRender((prev) => !prev);
      });
  };

  const handleChangeProductionQty = (e, partId, processName) => {
    dailyProductionData.processes.some((process, idx) => {
      let part_id = process.part_id?._id || process.part_id;
      if (process.process_name === processName && part_id === partId) {
        if (!e) {
          setRender((prev) => !prev);
          return true;
        }
        const values = { ...dailyProductionData };
        values.processes[idx].production = e.target.value;

        setDailyProductionData(values);
        return true;
      }
    });
  };
  const handleFailures = async (partId, index, processName) => {
    axios
      .get(
        `/api/getFailuresfromFmea/${partId}?process_name=${encodeURIComponent(
          processName
        )}`
      )
      .then((res) => {
        failures[index] = res.data;
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
        setRender((prev) => !prev);
      });
  };

  useEffect(() => {
    if (!id) return;
    if (partDetails.length) {
      axios
        .get(`/api/getOneDailyProduction/${id}`)
        .then((res) => {
          res.data.processes.map((item, index) => {
            if (item.part_id) {
              handleAllOperation(item.part_id._id, index);
              handleFailures(item.part_id._id, index, item.process_name);
            }
          });
          setDailyProductionData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [partDetails]);

  const handleChangeProduction = (e, index) => {
    const value = e.target.innerText;
    const name = e.target.dataset.name;
    dailyProductionData.processes[index][name] = value;
  };

  const handleInputValue = (e, index) => {
    dailyProductionData.processes[index][e.target.name] = e.target.value;
    setRender((prev) => !prev);
  };

  const selectFailure = (e, index) => {
    const value = e.target.value;
    dailyProductionData.processes[index].reason = value;
  };

  const handlePartDetails = (partId, value, index) => {
    dailyProductionData.processes[indexSelectPart.current].part_id = partId;
    dailyProductionData.processes[indexSelectPart.current].part_number = value;
    const getPartNo = partDetails.filter((part) => part._id === partId);

    dailyProductionData.processes[indexSelectPart.current].part_name =
      getPartNo[0].part_name;
    handleAllOperation(getPartNo[0]._id, indexSelectPart.current);
    if (dailyProductionData.processes[indexSelectPart.current].process_name) {
      handleFailures(
        getPartNo[0]._id,
        indexSelectPart.current,
        dailyProductionData.processes[indexSelectPart.current].process_name
      );
    }
  };

  const resetPartDetails = () => {
    dailyProductionData.processes[indexSelectPart.current].part_id = null;
    dailyProductionData.processes[indexSelectPart.current].part_number = null;
    dailyProductionData.processes[indexSelectPart.current].part_name = null;
    operation[indexSelectPart.current] = [];
    failures[indexSelectPart.current] = [];
    setRender((prev) => !prev);
  };

  const handle4m = (value, documentType) => {
    if (documentType === "machine") {
      dailyProductionData.processes[index4m.current].machine_no = value;
    }
    if (documentType === "operator") {
      dailyProductionData.processes[index4m.current].operator_name = value;
    }
    setRender((prev) => !prev);
  };
  const reset4m = (documentType) => {
    if (documentType === "machine") {
      dailyProductionData.processes[index4m.current].machine_no = null;
    }
    if (documentType === "operator") {
      dailyProductionData.processes[index4m.current].operator_name = null;
    }
    setRender((prev) => !prev);
  };

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        dailyProductionData.isSubmitted = bool;
        axios
          .put(`/api/updateDailyProduction/${id}`, dailyProductionData)
          .then((res) => {
            if (res.data.status === "success") {
              history.push("/viewDailyProduction");
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        dailyProductionData.isSubmitted = bool;
        axios
          .post(`/api/dailyProduction/create`, dailyProductionData)
          .then((res) => {
            if (res.data.status === "success") {
              history.push("/viewDailyProduction");
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 80;
    } else {
      document.getElementById("machinetable").scrollLeft -= 80;
    }
  };

  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <ButtonStyled
          left
          onClick={(e) => {
            handleScroll("left");
          }}
        >
          <div>
            <ArrowBackIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <ButtonStyled
          right
          onClick={(e) => {
            handleScroll("right");
          }}
        >
          <div>
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>

        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            DAILY PRODUCTION REPORT
          </Typography>
          <div style={{ display: "flex" }}>
            <Wrapper
              style={{
                gap: "1rem",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#003566",
                  marginRight: "0.5rem",
                }}
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleSubmitAndSave(false);
                }}
              >
                Save & Continue
              </Button>

              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#064a29",
                  marginRight: "0.5rem",
                }}
                startIcon={<CloudUploadIcon />}
                onClick={() => {
                  handleSubmitAndSave(true);
                }}
              >
                Submit
              </Button>
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (ans) {
                    history.goBack();
                  }
                }}
              />
            </Wrapper>
          </div>
        </div>
        <TableContainerStyled id="machinetable">
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
          >
            <thead>
              <tr style={{ height: "70px" }}>
                <th
                  colSpan="23"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  colSpan={3}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC. NO:- ARC/F/PRD/24
                </th>
              </tr>

              <tr>
                <th
                  colSpan="23"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  DAILY PRODUCTION REPORT
                </th>
                <th colSpan={3} style={{ border: "1px solid black" }}>
                  REV. NO.:- 00/01/07/2018
                </th>
              </tr>
              <tr>
                <th colSpan={8} style={{ border: "1px solid black" }}>
                  <Wrapper style={{ gap: "5px" }}>
                    DATE:
                    <DatePicker
                      // label="pic Date"
                      name="date_performance"
                      format="dd/MM/yyyy"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#f8f9fa",
                      }}
                      value={dailyProductionData.dailyProduction_date}
                      onChange={(date) => {
                        dailyProductionData.dailyProduction_date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  </Wrapper>
                </th>
                <th colSpan={4} style={{ border: "1px solid black" }}>
                  SHIFT:
                </th>
                <th
                  colSpan={5}
                  align="center"
                  contentEditable="true"
                  data-name="shift"
                  onKeyUp={(e) => {
                    dailyProductionData[e.target.dataset.name] =
                      e.target.innerText;
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {dailyProductionData.shift}
                </th>
                <th colSpan={4} style={{ border: "1px solid black" }}>
                  DEPARTMENT:
                </th>
                <th
                  colSpan={5}
                  align="center"
                  contentEditable="true"
                  data-name="department"
                  onKeyUp={(e) => {
                    dailyProductionData[e.target.dataset.name] =
                      e.target.innerText;
                  }}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {dailyProductionData.department}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "M/C NO.",
                  "OPERATOR NAME",
                  "PART NO.",
                  "PART NAME",
                  "OPERATION NO",
                  "LOT NO.",
                  "IN TIME",
                  "OUT TIME",
                  "WORKING HOUR",
                  "CYCLE TIME (IN SEC)",
                  "PRODUCTION",
                  "REJECTION",
                  "Reason of Failure",
                  "BREAKDOWNS",
                  "BREAKDOWN REASON",
                ].map((heading, index) => (
                  <th
                    key={index}
                    rowSpan={2}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
                <th style={cellStyle} colSpan={2}>
                  BREAKDOWN TIME
                </th>
                <th style={cellStyle} rowSpan={2}>
                  M/C HELD UP TIME
                </th>
                <th style={cellStyle} rowSpan={2}>
                  BREAKDOWN ATTENDED BY
                </th>
                <th style={cellStyle} rowSpan={2}>
                  BREAKDOWN WORK DONE
                </th>
                <th style={cellStyle} rowSpan={2}>
                  SPARE PARTS USED
                </th>
                <th style={cellStyle} rowSpan={2}>
                  BREAKDOWN QTY
                </th>
                <th style={cellStyle} rowSpan={2}>
                  BREAKDOWN REMARKS
                </th>
                <th style={cellStyle} rowSpan={2}>
                  REWORK
                </th>
                <th style={cellStyle} rowSpan={2}>
                  WORK EFF %
                </th>
                <th style={cellStyle} rowSpan={2}>
                  REMARK
                </th>
              </tr>
              <tr>
                <th>FROM</th>
                <th>TO</th>
              </tr>

              {dailyProductionData.processes.map((item, index) => (
                <tr key={item._id || index}>
                  <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <Wrapper
                      justify="space-between"
                      style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                    >
                      <p
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        {item.machine_no || "Pick Machine"}
                      </p>
                      {item.machine_no ? (
                        <Wrapper
                          className="pir_add_modal"
                          style={{ backgroundColor: "#003566" }}
                          justify="center"
                          onClick={() => {
                            item.machine_no = null;
                            setRender((prev) => !prev);
                          }}
                        >
                          <Remove />
                        </Wrapper>
                      ) : (
                        <Wrapper
                          className="pir_add_modal"
                          justify="center"
                          style={{ backgroundColor: "#495057" }}
                          onClick={() => {
                            index4m.current = index;
                            documentTypeRef.current = "machine";
                            setIs4mModal(true);
                          }}
                        >
                          <Add />
                        </Wrapper>
                      )}
                    </Wrapper>
                  </th>
                  <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <Wrapper
                      justify="space-between"
                      style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                    >
                      <p
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        {item.operator_name || "Pick Operator"}
                      </p>
                      {item.operator_name ? (
                        <Wrapper
                          className="pir_add_modal"
                          style={{ backgroundColor: "#003566" }}
                          justify="center"
                          onClick={() => {
                            item.operator_name = null;
                            setRender((prev) => !prev);
                          }}
                        >
                          <Remove />
                        </Wrapper>
                      ) : (
                        <Wrapper
                          className="pir_add_modal"
                          justify="center"
                          style={{ backgroundColor: "#495057" }}
                          onClick={() => {
                            index4m.current = index;
                            documentTypeRef.current = "operator";
                            setIs4mModal(true);
                          }}
                        >
                          <Add />
                        </Wrapper>
                      )}
                    </Wrapper>
                  </th>
                  <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <Wrapper
                      justify="space-between"
                      style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                    >
                      <p
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        {item?.part_id?.part_number ||
                          item?.part_number ||
                          "Pick Part No."}
                      </p>
                      {item?.part_number || item?.part_id?.part_number ? (
                        <Wrapper
                          className="pir_add_modal"
                          style={{ backgroundColor: "#003566" }}
                          justify="center"
                          onClick={() => {
                            item.part_number = null;
                            item.part_id = null;
                            item.part_name = null;
                            operation[index] = [];
                            failures[index] = [];
                            setRender((prev) => !prev);
                          }}
                        >
                          <Remove />
                        </Wrapper>
                      ) : (
                        <Wrapper
                          className="pir_add_modal"
                          justify="center"
                          style={{ backgroundColor: "#495057" }}
                          onClick={() => {
                            indexSelectPart.current = index;
                            selectSelectPartModal(true);
                          }}
                        >
                          <Add />
                        </Wrapper>
                      )}
                    </Wrapper>
                  </th>
                  <td
                    style={{
                      minWidth: "180px",
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ maxHeight: "50px", overflow: "auto" }}>
                      {item.part_name}
                    </div>
                  </td>
                  <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <FormControl
                      style={{
                        width: "250px",
                        border: "none",
                      }}
                      fullWidth={true}
                      variant="filled"
                      classes={{
                        root: classes.root,
                      }}
                    >
                      <InputLabel>Select Operation</InputLabel>
                      <Select
                        defaultValue={item.process_name || ""}
                        classes={{
                          root: classes.root,
                          select: classes.select,
                        }}
                        style={{ fontSize: "0.8rem" }}
                        disableUnderline
                        onChange={(e) => {
                          dailyProductionData.processes[index].process_name =
                            e.target.value;
                          handleFailures(
                            item.part_id._id || item.part_id,
                            index,
                            e.target.value
                          );
                          handleChangeProductionQty(
                            null,
                            item.part_id._id || item.part_id,
                            e.target.value
                          );
                        }}
                      >
                        {operation[index].map((data, opIndex) => (
                          <MenuItem
                            className="option"
                            key={opIndex}
                            style={{ fontSize: "0.8rem" }}
                            value={data.process_name}
                          >
                            {data.process_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </th>
                  <td
                    align="center"
                    contentEditable="true"
                    data-name="lot_no"
                    onKeyUp={(e) => {
                      handleChangeProduction(e, index);
                    }}
                    suppressContentEditableWarning="true"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                      minWidth: "90px",
                    }}
                  >
                    {item.lot_no}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                      minWidth: "100px",
                    }}
                  >
                    <TimePicker
                      name="in_time"
                      style={{
                        backgroundColor: "#f8f9fa",
                        padding: "8px 5px",
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={item.in_time || null}
                      onChange={(date) => {
                        dailyProductionData.processes[index].in_time = date;
                        // const newTime =
                        //   new Date(
                        //     dailyProductionData.processes[index].out_time
                        //   ).getTime() -
                        //   new Date(
                        //     dailyProductionData.processes[index].in_time
                        //   ).getTime();
                        // if (newTime < 0) {
                        //   dailyProductionData.processes[index].out_time = null;
                        //   dailyProductionData.processes[index].in_time = null;
                        // }
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                      minWidth: "100px",
                    }}
                  >
                    <TimePicker
                      name="out_time"
                      style={{
                        backgroundColor: "#f8f9fa",
                        padding: "8px 5px",
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={item.out_time || null}
                      onChange={(date) => {
                        dailyProductionData.processes[index].out_time = date;
                        // const newTime =
                        //   new Date(
                        //     dailyProductionData.processes[index].out_time
                        //   ).getTime() -
                        //   new Date(
                        //     dailyProductionData.processes[index].in_time
                        //   ).getTime();
                        // if (newTime < 0) {
                        //   dailyProductionData.processes[index].out_time = null;
                        //   dailyProductionData.processes[index].in_time = null;
                        // }
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      minWidth: "90px",
                    }}
                  >
                    {calculateWorkingHour(item.in_time, item.out_time)}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <input
                      autoComplete="off"
                      className="input"
                      name="cycle_time"
                      type="number"
                      defaultValue={item.cycle_time}
                      onChange={(e) => handleInputValue(e, index)}
                    />
                  </td>
                  <th
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <input
                      autoComplete="off"
                      name="production"
                      className="input"
                      type="number"
                      disabled={!item.process_name}
                      onChange={(e) =>
                        handleChangeProductionQty(
                          e,
                          item.part_id._id || item.part_id,
                          item.process_name
                        )
                      }
                      value={
                        dailyProductionData.processes.find(
                          (process) =>
                            process.process_name === item.process_name &&
                            (item.part_id?._id || item.part_id) ===
                              (process.part_id?._id || process.part_id)
                        ).production
                      }
                    />
                  </th>
                  <th
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <input
                      autoComplete="off"
                      className="input"
                      name="rejection"
                      type="number"
                      defaultValue={item.rejection}
                      onChange={(e) => handleInputValue(e, index)}
                    />
                  </th>

                  <th
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <FormControl
                      style={{
                        width: "250px",
                        border: "none",
                      }}
                      fullWidth={true}
                      variant="filled"
                      classes={{
                        root: classes.root,
                      }}
                    >
                      <InputLabel>Select Reason</InputLabel>
                      <Select
                        name="reason"
                        defaultValue={item.reason || ""}
                        classes={{
                          // underline: classes.underline,
                          root: classes.root,
                          select: classes.select,
                        }}
                        style={{ fontSize: "0.8rem" }}
                        disableUnderline
                        onChange={(e) => selectFailure(e, index)}
                      >
                        {failures[index].map((data, PIndex) => (
                          <MenuItem
                            className="option"
                            style={{ fontSize: "0.8rem" }}
                            key={PIndex}
                            value={`${data.potential_mode} , ${data.potential_cause[0]}`}
                          >
                            {data.potential_mode} , {data.potential_cause[0]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </th>
                  <th>
                    <FormControl
                      style={{
                        width: "200px",
                        border: "none",
                      }}
                      fullWidth={true}
                      variant="filled"
                      classes={{
                        root: classes.root,
                      }}
                    >
                      <InputLabel>Select Breakdown</InputLabel>
                      <Select
                        defaultValue={item.breakdown || ""}
                        classes={{
                          root: classes.root,
                          select: classes.select,
                        }}
                        style={{ fontSize: "0.8rem" }}
                        disableUnderline
                        onChange={(e) =>
                          (dailyProductionData.processes[index].breakdown =
                            e.target.value)
                        }
                      >
                        <MenuItem
                          style={{ fontSize: "0.8rem" }}
                          className="option"
                          value=""
                        >
                          None
                        </MenuItem>
                        <MenuItem
                          style={{ fontSize: "0.8rem" }}
                          className="option"
                          value="Machine Breakdown"
                        >
                          Machine Breakdown
                        </MenuItem>
                        <MenuItem
                          style={{ fontSize: "0.8rem" }}
                          className="option"
                          value="Mold Breakdown"
                        >
                          Mold Breakdown
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </th>
                  <th align="center">
                    {item.breakdown_reason === "OTHER" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            style={{ width: "100px" }}
                            autoComplete="off"
                            className="input"
                            name="cycle_time"
                            type="textfield"
                            defaultValue={item.breakdown_reason_other}
                            onChange={(e) => {
                              item.breakdown_reason_other = e.target.value;
                            }}
                          />
                          <Tooltip title="Delete">
                            <IconButton
                              aria-label="delete"
                              onClick={(e) => {
                                item.breakdown_reason = "";
                                item.breakdown_reason_other = "";
                                item.breakdown_attended = "";
                                item.breakdown_work_done = "";
                                item.spare_parts = "";
                                item.breakdown_remarks = "";
                                item.breakdown_qty = "";
                                setRender((prev) => !prev);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </>
                    ) : (
                      <FormControl
                        style={{
                          width: "200px",
                          border: "none",
                        }}
                        fullWidth={true}
                        variant="filled"
                        classes={{
                          root: classes.root,
                        }}
                      >
                        <InputLabel>Select Breakdown Reason</InputLabel>
                        <Select
                          defaultValue={item.breakdown_reason || ""}
                          classes={{
                            root: classes.root,
                            select: classes.select,
                          }}
                          style={{ fontSize: "0.8rem" }}
                          disableUnderline
                          onChange={(e) => {
                            dailyProductionData.processes[
                              index
                            ].breakdown_reason = e.target.value;

                            if (!e.target.value) {
                              item.breakdown_attended = "";
                              item.breakdown_work_done = "";
                              item.spare_parts = "";
                              item.breakdown_remarks = "";
                              item.breakdown_qty = "";
                            }
                            setRender((prev) => !prev);
                          }}
                        >
                          <MenuItem
                            style={{ fontSize: "0.8rem" }}
                            className="option"
                            value=""
                          >
                            None
                          </MenuItem>
                          {breakdownReasons.map((breakdown_reason, brIndex) => (
                            <MenuItem
                              style={{ fontSize: "0.8rem" }}
                              className="option"
                              value={breakdown_reason}
                              key={brIndex}
                            >
                              {breakdown_reason}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </th>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                      minWidth: "100px",
                    }}
                  >
                    <TimePicker
                      name="breakdown_time_from"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#f8f9fa",
                        padding: "8px 5px",
                      }}
                      value={item.breakdown_time_from || null}
                      onChange={(date) => {
                        dailyProductionData.processes[
                          index
                        ].breakdown_time_from = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                      minWidth: "100px",
                    }}
                  >
                    <TimePicker
                      name="breakdown_time_to"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#f8f9fa",
                        padding: "8px 5px",
                      }}
                      value={item.breakdown_time_to || null}
                      onChange={(date) => {
                        dailyProductionData.processes[index].breakdown_time_to =
                          date;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      minWidth: "90px",
                    }}
                  >
                    {calculateWorkingHour(
                      item.breakdown_time_from,
                      item.breakdown_time_to
                    )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <ControlledInput
                      onUpdate={(val) => {
                        item.breakdown_attended = val;
                      }}
                      disable={
                        !item.breakdown_reason && !item.breakdown_reason_other
                      }
                      defaultvalue={item.breakdown_attended}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <ControlledInput
                      onUpdate={(val) => {
                        item.breakdown_work_done = val;
                      }}
                      disable={
                        !item.breakdown_reason && !item.breakdown_reason_other
                      }
                      defaultvalue={item.breakdown_work_done}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <ControlledInput
                      onUpdate={(val) => {
                        item.spare_parts = val;
                      }}
                      disable={
                        !item.breakdown_reason && !item.breakdown_reason_other
                      }
                      defaultvalue={item.spare_parts}
                    />
                  </td>

                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <ControlledInput
                      onUpdate={(val) => {
                        item.breakdown_qty = val;
                      }}
                      disable={
                        !item.breakdown_reason && !item.breakdown_reason_other
                      }
                      defaultvalue={item.breakdown_qty}
                    />
                  </td>

                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                    }}
                  >
                    <ControlledInput
                      onUpdate={(val) => {
                        item.breakdown_remarks = val;
                      }}
                      disable={
                        !item.breakdown_reason && !item.breakdown_reason_other
                      }
                      defaultvalue={item.breakdown_remarks}
                    />
                  </td>

                  <td
                    align="center"
                    contentEditable="true"
                    data-name="rework"
                    onKeyUp={(e) => {
                      handleChangeProduction(e, index);
                    }}
                    suppressContentEditableWarning="true"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                      minWidth: "90px",
                    }}
                  >
                    {item.rework}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      minWidth: "100px",
                    }}
                  >
                    {calculateWorkingEff(
                      item.in_time,
                      item.out_time,
                      item.cycle_time,
                      item.production
                    )}
                  </td>
                  <td
                    align="center"
                    contentEditable="true"
                    data-name="remark"
                    onKeyUp={(e) => {
                      handleChangeProduction(e, index);
                    }}
                    suppressContentEditableWarning="true"
                    style={{
                      border: "1px solid black",
                      backgroundColor: "#f9e0e0",
                      minWidth: "250px",
                    }}
                  >
                    {item.remark}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainerStyled>
      </div>
      <Modal
        openPopup={is4mModal}
        title={`${
          documentTypeRef.current === "operator"
            ? "OPERATOR LIST"
            : "MACHINE LIST"
        }`}
        closeModal={(e) => setIs4mModal(false)}
      >
        <ForMTable
          {...{ handle4m, reset4m }}
          processes={dailyProductionData.processes[index4m.current]}
          index4m={index4m.current}
          documentType={documentTypeRef.current}
        />
      </Modal>
      <Modal
        openPopup={isSelectPartModal}
        title="Select PART"
        closeModal={(e) => selectSelectPartModal(false)}
      >
        <SelectPart
          {...{ handlePartDetails, resetPartDetails }}
          processes={dailyProductionData.processes[indexSelectPart.current]}
        />
      </Modal>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default DailyProductionTable;
