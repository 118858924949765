import React, { useEffect, useState, useRef } from "react";

import Button from "@material-ui/core/Button";
import axios from "axios";
// import Input from "../../components/Control/Input";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import SaveIcon from "@material-ui/icons/Save";
// import ClearAllIcon from "@material-ui/icons/ClearAll";
import "../../../style/product.css";
import useConfirm from "../../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { DivStyled } from "../Container.styled";

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const AddEditFaculty = ({ setIsNewFacultyModal, id }) => {
  const classes = dropdownStyles();
  const [facultyData, setFacultyData] = useState({});
  const [render, setRender] = useState(false);
  const inputElement = useRef(null);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "Supplier",
    `Are you sure you want to ${id ? "update" : "save"} this part?`
  );

  function handleSupplierData(e) {
    setFacultyData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getFacultyById/${id}`)
        .then((res) => setFacultyData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async () => {
    // e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/updateFaculty/${id}`, facultyData)
        .then((res) => {
          setIsNewFacultyModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/createFaculty", facultyData)
        .then((res) => {
          setIsNewFacultyModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <section className="root-admin">
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          {id ? "UPDATE" : "SUBMIT"}
        </Button>
      </div>
      <DivStyled>
        <label htmlFor="faculty_name">Faculty Name:</label>
        <input
          type="text"
          id="faculty_name"
          name="faculty_name"
          ref={inputElement}
          defaultValue={facultyData?.faculty_name}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="address">Address:</label>
        <input
          type="text"
          id="address"
          name="address"
          defaultValue={facultyData?.address}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="category">Category : </label>
        <div
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: facultyData?.category ? "7.2rem" : "8.9rem",
          }}
        >
          <Select
            defaultValue={facultyData?.category || ""}
            key={facultyData?.category}
            classes={{
              root: classes.root,
              select: classes.select,
            }}
            style={{ fontSize: "0.8rem", width: "100%" }}
            disableUnderline
            onChange={(e) => {
              facultyData.category = e.target.value;
              setRender((prev) => !prev);
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Internal">Internal</MenuItem>
            <MenuItem value="External">External</MenuItem>
          </Select>
        </div>
      </DivStyled>
      <DialogSubmit isSubmitContent={false} />
    </section>
  );
};

export default AddEditFaculty;
