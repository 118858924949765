import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import AddEditInstrument from "./AddEditInstrument";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
// import { useAuth } from "../../context/GlobalContext";
import useConfirm from "../../components/Control/useConfirm";
import "../style.css";
import formatDate from "../../components/Control/formatDate";
import { useHistory } from "react-router-dom";
import CategoryList from "./CategoryList";

const freq_in_month = {
  1: "1 Month",
  3: "3 Month",
  6: "6 Month",
  12: "12 Month",
};
const InsrumentsList = () => {
  const [data, setData] = useState([]);
  const [isNewInstrumentModal, setIsNewInstrumentModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  // const { state } = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("");
  const [instrumentId, setInstrumentId] = useState(null);
  const [isCategoryModal, setIsCategoryModal] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    axios
      .get("/api/getInstrumentCategory")
      .then((res) => {
        if (res.data) {
          setCategoryData(res.data);
          if (res.data.length) {
            setFilter(res.data[0]?.name);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [render]);
  const history = useHistory();

  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this  ?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(`/api/get/instrument?page=${page}&search=${search}&filter=${filter}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    filter,
    // duplicateOpenModal,
    isNewInstrumentModal,
  ]);

  async function deleteInstrument(id) {
    const ans = await confirmDelete();
    if (ans) {
      await axios
        .delete(`/api/delete_instrument/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }

  function calculateDueDate(date, freq) {
    if (
      date === undefined ||
      date === null ||
      date === " " ||
      date === "" ||
      freq === "" ||
      freq === 0 ||
      freq === undefined
    ) {
      return "";
    }

    // const freqInMonth = Math.ceil(freq * 12);
    const freqInMonth = freq;

    let calcDate = new Date(date);

    const dueDateMili = calcDate.setMonth(
      calcDate.getMonth() + freqInMonth,
      calcDate.getDate() - 1
    );

    const dueDate = new Date(dueDateMili).toLocaleDateString("en-GB");
    return dueDate;
  }

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          INSTRUMENT LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setIsCategoryModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              CATEGORY
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setInstrumentId(null);
                setIsNewInstrumentModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Instrument
            </Button>
          </div>
        </div>
        <Wrapper>
          <div style={{ flex: "0.8" }}>
            <Input
              placeholder="Search Instrument Desc."
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{
              marginRight: "1.5rem",
              marginTop: "0.8rem",
              flex: "0.2",
            }}
          >
            <InputLabel>Insrument Type</InputLabel>
            <Select
              // name="instrument_type"
              onChange={(e) => setFilter(e.target.value)}
              value={filter}
            >
              {categoryData.map((category, index) => (
                <MenuItem value={category.name} key={index + 200.0202}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Wrapper>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                {[
                  "S.No",
                  "INSTRUMENT TYPE",
                  "DESCRIPTION",
                  // "SOURCE",
                  "UNIQUE I.D NO.",
                  "CALIBRATION FREQ.:",
                  // "Calibration On Date",
                  // "Calibration Due On Date",
                  // "DELAY IF ANY",
                  "Range",
                  "Least Count",
                  "DEPARTMENT",
                  "REMARKS",
                  "Action",
                ].map((heading, index) => (
                  <TableCell
                    key={index + 1.00001}
                    style={{ fontWeight: "bold", textTransform: "uppercase" }}
                    align="center"
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  // <React.Fragment key={item._id}>
                  <TableRow key={item._id}>
                    <TableCell align="center">
                      {perPage * (page - 1) + index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.instrument_type}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.description}
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.source}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.id_no}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {freq_in_month[item?.frequency_cycle]}
                    </TableCell>
                    {/* <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {formatDate(item?.calibration_done_date)}
                    </TableCell> */}
                    {/* <TableCell
                      align="center"
                      style={{
                        textTransform: "capitalize",
                        ...(new Date(
                          calculateDueDate(
                            item?.calibration_done_date,
                            +item?.frequency_cycle
                          )
                        ) < new Date() && {
                          background: "red",
                          color: "white",
                        }),
                      }}
                    >
                      {calculateDueDate(
                        item?.calibration_done_date,   <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.delay}
                    </TableCell>
                        +item?.frequency_cycle
                      )}
                    </TableCell> */}
                    {/* <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.delay}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.range}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.least_count}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.department?.department_name}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.remarks}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", width: "150px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          minWidth: "150px",
                          width: "150px",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title="Edit Instrument" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: ".5rem",
                            }}
                            onClick={() => {
                              setInstrumentId(item._id);
                              setIsNewInstrumentModal(true);
                            }}
                          >
                            <EditIcon
                              style={{ fontSize: "1.4rem", color: "black" }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete Instrument" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: "0.8rem",
                            }}
                            onClick={() => {
                              deleteInstrument(item._id);
                            }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                  // </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={"CATEGORY LIST"}
          openPopup={isCategoryModal}
          closeModal={() => {
            setIsCategoryModal(false);
          }}
          backgroundColor="white"
        >
          <CategoryList />
        </Modal>
        <Modal
          title={instrumentId ? "update instrument" : "create new instuement"}
          openPopup={isNewInstrumentModal}
          closeModal={() => {
            setIsNewInstrumentModal(false);
            setInstrumentId(null);
          }}
          backgroundColor="white"
        >
          <AddEditInstrument
            id={instrumentId}
            {...{ setIsNewInstrumentModal }}
          />
        </Modal>
      </main>
      <DialogDelete />
    </>
  );
};

export default InsrumentsList;
