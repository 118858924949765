export const Spot_Welding = [
  {
    check_point: "Check the Electrical connections for tightness.",
  },
  {
    check_point: "Check the air leakage.",
  },
  {
    check_point: "Check the electrode for damage and mounting.",
  },
  {
    check_point: "Check /clean the solenoid valve.",
  },
  {
    check_point: "Clean FRL.",
  },
  {
    check_point: "Check cylinder Fittings for tightness.",
  },
  {
    check_point: "Check the controller for proper working.",
  },
  {
    check_point: "Check the machine for cleaning.",
  },
];

export const Grinding = [
  {
    check_point: "Check the stater.",
  },
  {
    check_point: "Clean / check the motor.",
  },
  {
    check_point: "Check the lubrication of motor bearing.",
  },
  {
    check_point: "Check the key & key ways.",
  },
];

export const Bending_Machine_01 = [
  {
    check_point: "Clean / change oil filter.",
  },
  {
    check_point: "Clean  oil and topping up to be done.",
  },
  {
    check_point: "Check for oil level.",
  },
  {
    check_point: "Check for oil leakage.",
  },
  {
    check_point: "Check the seals for leakage & damage.",
  },
  {
    check_point: "Clean the direction control valve.",
  },
  {
    check_point: "Clean  the pressure relief valve.",
  },
  {
    check_point: " Check the electrical circuit panel for working.",
  },
  {
    check_point: "Check motor bearing for undue noise & greasing to be done.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
  {
    check_point: "Check/Clean sensors for proper working & firm mounting.",
  },
  {
    check_point: "Check the Indicators for proper working.",
  },
  {
    check_point: "Check the hose pipe for any oil leakage.",
  },
  {
    check_point: "Check limit switches for proper working & firm mounting.",
  },
];

export const Induction_Brazing_Machine = [
  {
    check_point: "Check the water leakage.",
  },
  {
    check_point: "Check the heat coil.",
  },
  {
    check_point: "Check the control card.",
  },
  {
    check_point: "Clean / Check the electric circuit panel.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
  {
    check_point: "Clean the heating coil by air.",
  },
  {
    check_point: "Clean the water pipe pipe line.",
  },
  {
    check_point: "Check the capacitor.",
  },
];

export const NRV_Testing_Machine = [
  {
    check_point: "Check the Electrical connections for tightness.",
  },
  {
    check_point: "Check the air leakage.",
  },
  {
    check_point: "Check /clean the solenoid valve.",
  },
  {
    check_point: "Clean FRL.",
  },
  {
    check_point: "Check cylinder Fittings for tightness.",
  },
  {
    check_point: "Check the controller for proper working.",
  },
  {
    check_point: "Check the machine for cleaning.",
  },
];

export const Pera_Flange_Machine = [
  {
    check_point: "Check DOL starter.",
  },
  {
    check_point: "Check the motor for working and proper mounting.",
  },
  {
    check_point: "Check the gear box for physical condition and undue noise.",
  },
  {
    check_point: "Check the gear box seals for leakage.",
  },
  {
    check_point: "Lubricate the  motor bearing.",
  },
  {
    check_point: "Check the OLR for setting as per motor size.",
  },
];

export const Bending_Machine_02 = [
  {
    check_point: "Clean HMI with soft & clean cloth.",
  },
  {
    check_point: "Check sliding guides for lubrication.",
  },
  {
    check_point: "Check lubrication tank for oil level.",
  },
  {
    check_point: "Check limit switches for working and firm mounting.",
  },
  {
    check_point: "Check the penumatic cylinder for leakage & damage.",
  },
  {
    check_point: "Check the electrical circuit panel for working.",
  },
  {
    check_point: "Check panel AC for working.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
  {
    check_point: "Clean Safety  sensors with soft cloth & Check for working.",
  },
  {
    check_point: "Check electrical connections for tightness.",
  },
  {
    check_point: "Clean FRL for proper working.",
  },
];

export const Flushing_Machine = [
  {
    check_point: "Clean FRL.",
  },
  {
    check_point: "Change oil.",
  },
  {
    check_point: "Check the oil leakage.",
  },
  {
    check_point: "Check the oil pump for working.",
  },
  {
    check_point: "Check the air leakage.",
  },
  {
    check_point: "Check the PLC connection for tightness .",
  },
  {
    check_point: "Check the safety door sensor for working.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
  {
    check_point: "Check/Clean oil filters.",
  },
  {
    check_point:
      "Check mist cleaner contactor for physical condition and working.",
  },
];

export const Pipe_Flairing_Machine = [
  {
    check_point: "Clean / Change oil filter",
  },
  {
    check_point: "Change the oil.",
  },
  {
    check_point: "Check for oil level.",
  },
  {
    check_point: "Check for oil leakage.",
  },
  {
    check_point: "Check the seals for leakage.",
  },
  {
    check_point: "Check electrical circuit panel for working.",
  },
  {
    check_point: "Check the lubrication of motor bearing.",
  },
  {
    check_point: "Clean the control panel by brush.",
  },
  {
    check_point: "Check the sensors for working.",
  },
  {
    check_point: "Check the limit switches for firm mounting and working.",
  },
  {
    check_point: "Check the hydraullic pipe connector.",
  },
  {
    check_point: "Check/clean pilot check valve & solenoid valve.",
  },
  {
    check_point: "Check pressure relief valve o'ring & leakage.",
  },
];

export const Tig_Welding = [
  {
    check_point: "Check the Gas connections for tightness.",
  },
  {
    check_point: "Check for gas leakage.",
  },
  {
    check_point: "Check the welding tourch for physical condition & working.",
  },
  {
    check_point: "Clean  the control card.",
  },
  {
    check_point: "Check the transformer for physical condition & working.",
  },
  {
    check_point: "Clean the machine with blower.",
  },
  {
    check_point: "Check Machine for proper earthing.",
  },
  {
    check_point: "Check electrical connections for tightness.",
  },
  {
    check_point: "Check gas regulator Pr. Switches for proper working.",
  },
  {
    check_point: "Clean  solenoid valve & check for working.",
  },
];

export const Clamping = [
  {
    check_point: "Clean FRL",
  },
  {
    check_point: "Check the leakage of air.",
  },
  {
    check_point: "Check the electrical circuits.",
  },
  {
    check_point: "Check grease in Pincer.",
  },
  {
    check_point: "Check pneumatic seal.",
  },
];

export const Ultrasonic_1 = [
  {
    check_point: "Clean FRL & Check for working  .",
  },
  {
    check_point: "Change the DM water.",
  },
  {
    check_point: " Change water filter(subjected to physical condition).",
  },
  {
    check_point: "Clean the panel by brush, air blower.",
  },
  {
    check_point: "Check the control panel and Indicators for working.",
  },
  {
    check_point: "Check the timer for working.",
  },
  {
    check_point: "Check the sensors for working.",
  },
  {
    check_point: "Check the motor  for unwanted noice.",
  },
  {
    check_point: "Check the indicators for working.",
  },
  {
    check_point: "Check the heater for working.",
  },
  {
    check_point: "Check the machine for working in auto mode.",
  },
  {
    check_point: "Clean wire Magnitic filter  Filters .",
  },
  {
    check_point: "Clean the water tank properly .",
  },
  {
    check_point: "Check the tube internal scaling.",
  },
];

export const Oven = [
  {
    check_point: "Check the controller for working.",
  },
  {
    check_point: "Check the timer.",
  },
  {
    check_point: "Cleaning oven from inside.",
  },
  {
    check_point: "Check temp. controller.",
  },
  {
    check_point: "Check the electeical wires.",
  },
  {
    check_point: "Check the heater.",
  },
  {
    check_point: "check oven door open after complete the cycle .",
  },
];

export const Farule_Fitting_Machine = [
  {
    check_point: "Check the Electrical connections for tightness.",
  },
  {
    check_point: "Check the electrical motor for smooth working.",
  },
  {
    check_point: "Check tools for physical condition.",
  },
  {
    check_point: "Check control panelfor working.",
  },
  {
    check_point: "check emergency switch for working.",
  },
  {
    check_point: "Check safety cover for working.",
  },
  {
    check_point: "Clean the machine .",
  },
  {
    check_point: "Check the Machine for Oil level.",
  },
];

export const Magna_Flux = [
  {
    check_point: "Clean / change oil filter.",
  },
  {
    check_point: "Change oil.",
  },
  {
    check_point: "Check the oil level.",
  },
  {
    check_point: "Check the oil leakage.",
  },
  {
    check_point: "Check the seals.",
  },
  {
    check_point: "Check the pneumatic cylinder seals.",
  },
  {
    check_point: "Check  the mercury bulb.",
  },
  {
    check_point: "Check the lubrication of motor bearing.",
  },
  {
    check_point: "Check for loose wiring .",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
  {
    check_point: "Check oil pump for working .",
  },
];

export const Lazer_Marking = [
  {
    check_point: "Check the Electrical connections for tightness.",
  },
  {
    check_point: "Clean the screen by soft cloth.",
  },
  {
    check_point: "Check the Machine for working.",
  },
  {
    check_point: "Check the controlpanel for working.",
  },
  {
    check_point: "Check the switches for working.",
  },
  {
    check_point: "Check the key board for working.",
  },
];

export const Air_Decay = [
  {
    check_point: "Clean FRL.",
  },
  {
    check_point: "Check the air leakage.",
  },
  {
    check_point: "Check  the pneumatic seals.",
  },
  {
    check_point: "Clean the control panel by brush, air blower.",
  },
  {
    check_point: "Check the control panel.",
  },
  {
    check_point: "Clean the direction control valve.",
  },
  {
    check_point: "Check red bin sensor for working .",
  },
];

export const Soco_Cutter_Machine = [
  {
    check_point: "Check the push button for working.",
  },
  {
    check_point: "Check the spring.",
  },
  {
    check_point: "Check for air leakage.",
  },
  {
    check_point: "Check the cutting wheel.",
  },
  {
    check_point: "Check/clean the motor for bearing condition.",
  },
  {
    check_point: "Check coolant pump for working.",
  },
  {
    check_point: "Check limit switch for working and mountings.",
  },
  {
    check_point: "check/clean FRL.",
  },
  {
    check_point: "Check /Clean control panel.",
  },
];

export const Crimping_Machine = [
  {
    check_point: "Clean / Change oil filter",
  },
  {
    check_point: "Change the oil.",
  },
  {
    check_point: "Check for oil level.",
  },
  {
    check_point: "Check for oil leakage.",
  },
  {
    check_point: "Check electrical circuit panel for working.",
  },
  {
    check_point: "Check the lubrication of motor bearing.",
  },
  {
    check_point: "Check the solenoid coil for proper working.",
  },
  {
    check_point: "check the hydraullic pipe connectors.",
  },
];

export const Ss_Furnace = [
  {
    check_point:
      "Check conveyor Condition Should be cleaned and adjust conyeyor tention.",
  },
  {
    check_point: "Check gearbox oil level if equired toppedup.",
  },
  {
    check_point: "Check roller bearing condition and should be regreased.",
  },
  {
    check_point: "Check PID temperature controller working properly.",
  },
  {
    check_point: "Check low and high level temperature alarm working Properly.",
  },
  {
    check_point: "Check thermocouple condition.",
  },
  {
    check_point: "Check heater condition.",
  },
  {
    check_point: "Check fuse condition.",
  },
  {
    check_point: "check solinoid valve condition.",
  },
  {
    check_point: "check ammonia pressure switch.",
  },
  {
    check_point: "Check NH3 release valve.",
  },
];

export const Cutting_Machine = [
  {
    check_point: "Check the push button for working.",
  },
  {
    check_point: "Check the spring.",
  },
  {
    check_point: "Check the cutting wheel.",
  },
  {
    check_point: "Check/clean the motor for bearing condition.",
  },
  {
    check_point: "Check coolant pump for working.",
  },
  {
    check_point: "Check limit switch for working and mountings.",
  },
  {
    check_point: "Check /Clean control panel.",
  },
];

export const Benjo_Press_Machine = [
  {
    check_point: "Clean / Change oil filter",
  },
  {
    check_point: "Change the oil.",
  },
  {
    check_point: "Check for oil leakage.",
  },
  {
    check_point: "Check for oil level.",
  },
  {
    check_point: "Check the stater.",
  },
  {
    check_point: "Clean / check the motor.",
  },
  {
    check_point: "Check the lubrication of motor bearing.",
  },
  {
    check_point: "Check electrical circuit panel for working.",
  },
  {
    check_point: "Clean the control panel by brush.",
  },
  {
    check_point:
      "Check the limit switches for firm mounting and proper setting.",
  },
  {
    check_point: "check the hydraullic pipe connectors.",
  },
];

export const Deburring_Machine = [
  {
    check_point: "Check the motor bearing.",
  },
  {
    check_point: "Clean / Check the electric circuit panel.",
  },
  {
    check_point: "Check the lubrication of motor bearing.",
  },
  {
    check_point: "Check the V Belt & Belt alignment",
  },
  {
    check_point: "Check the key & key ways.",
  },
  {
    check_point: "Clean the control panel by brush, air blower.",
  },
];

export const Oil_Flushing_Machine = [
  {
    check_point: "Clean FRL.",
  },
  {
    check_point: "Change oil.",
  },
  {
    check_point: "Check the oil leakage.",
  },
  {
    check_point: "Check the oil pump for working.",
  },
  {
    check_point: "Check the air leakage.",
  },
  {
    check_point: "Check the PLC connection for tightness.",
  },
  {
    check_point: "Check the safety door sensor for working.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
  {
    check_point: "Check/Clean oil filters.",
  },
  {
    check_point:
      "Check mist cleaner contactor for physical condition and working.",
  },
];

export const Polishing_Machine = [
  {
    check_point: "Check the push button for working.",
  },
  {
    check_point: "Check the Polishing wheel.",
  },
  {
    check_point: "Check/clean the motor for bearing condition.",
  },
  {
    check_point: "Check /Clean control panel.",
  },
  {
    check_point: "Check electrical circuit panel for working.",
  },
];

export const End_Forming_Machine = [
  {
    check_point: "Clean the slide bearings",
  },
  {
    check_point: "Check the slide bearings for wear , play and running noises ",
  },
  {
    check_point: "Lubricate the bearings .",
  },
  {
    check_point: "Clean pinions and gear racks",
  },
  {
    check_point: "Check all gears for leakage , play and running noises.",
  },
  {
    check_point: "Check the tightening torque.",
  },
];

export const Pad_Printing = [
  {
    check_point: "Clean FRL.",
  },
  {
    check_point: "Check the air leakage.",
  },
  {
    check_point: "Check  the pneumatic seals.",
  },
  {
    check_point: "Clean the control panel by brush, air blower.",
  },
  {
    check_point: "Check the control panel.",
  },
  {
    check_point: "Clean the direction control valve.",
  },
];

export const Centre_Lathe_Machine = [
  {
    check_point: "Check the electrical circuit panel.",
  },
  {
    check_point: "Check the die holder spring.",
  },
  {
    check_point: "Check the worm shaft & nut.",
  },
  {
    check_point: "Check the lubrication of motor bearring.",
  },
  {
    check_point: "Check the gear box.",
  },
  {
    check_point: "Check the belt.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
];

export const Hydraullic_Press_1 = [
  {
    check_point: "Check for oil leakage.",
  },
  {
    check_point: "Check for oil level.",
  },
  {
    check_point: "Check the start push botton working properly.",
  },
  {
    check_point: "Check electrical circuit panel for working.",
  },
  {
    check_point:
      "Check the limit switches for firm mounting and proper setting.",
  },
  {
    check_point: "check the hydraullic pipe connectors.",
  },
];

export const Radial_Drill_Machine = [
  {
    check_point: " Check the starter.",
  },
  {
    check_point: "Check the electric motor for undue noise.",
  },
  {
    check_point: "Check the gear box for physical condition.",
  },
  {
    check_point: "Check the drill chuck for physical condition.",
  },
  {
    check_point: "Check  for loose wiring.",
  },
  {
    check_point: "Check the emergency switch.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
];

export const Ultrasonic_2 = [
  {
    check_point: "Clean FRL & Check for working.",
  },
  {
    check_point: "Change the DM water.",
  },
  {
    check_point: "Change water filter(subjected to physical condition).",
  },
  {
    check_point: "Clean the panel by brush, air blower.",
  },
  {
    check_point: "Check the control panel and Indicators for working.",
  },
  {
    check_point: "Check the timer for working.",
  },
  {
    check_point: "Check the sensors for working.",
  },
  {
    check_point: "Check the motor  for unwanted noice .",
  },
  {
    check_point: "Check the indicators for working.",
  },
  {
    check_point: "Check the heater for working.",
  },
  {
    check_point: "Check the machine for working in auto mode.",
  },
  {
    check_point: "Clean wire Magnitic filter  Filters.",
  },
  {
    check_point: "Clean the water tank properly.",
  },
];

export const Hydraullic_Press_2 = [
  {
    check_point: "Check for oil leakage.",
  },
  {
    check_point: "Check for oil level.",
  },
  {
    check_point: "Check electrical circuit panel for working.",
  },
  {
    check_point: "check the hydraullic pipe connectors.",
  },
  {
    check_point:
      "Check the limit switches for firm mounting and proper setting.",
  },
  {
    check_point: "Clean the control panel by brush.",
  },
  {
    check_point: "Check",
  },
];

export const Surface_Grinder = [
  {
    check_point: "Check the stater.",
  },
  {
    check_point: "Check the lubrication of slide guides.",
  },
  {
    check_point: "Clean / check the motor.",
  },
  {
    check_point: "Check the magnetic bed for condition.",
  },
  {
    check_point: "Check the lubrication of motor bearing.",
  },
  {
    check_point: "Check the key & key ways.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
  {
    check_point: "Check the cross screw for physical condition.",
  },
  {
    check_point: "Check cross screw for wear out.",
  },
  {
    check_point:
      "Check electrical motor for undue noise and physical condition",
  },
];

export const Blockage_Testing_Machine = [
  {
    check_point: "Clean FRL",
  },
  {
    check_point: "Check the  air leakage.",
  },
  {
    check_point: "Check  the pneumatic seals.",
  },
  {
    check_point: "Clean the control panel by brush, air blower.",
  },
  {
    check_point: "Check the control panel.",
  },
  {
    check_point: "Check all nut and bolts for tightness.",
  },
];

export const Drill_Machine = [
  {
    check_point: "Clean/Check motor for undue noise.",
  },
  {
    check_point: "Check V-belt for physical condition.",
  },
  {
    check_point: "Check drill chuck for pysical contition.",
  },
  {
    check_point: "Check reverse forward switch for working.",
  },
  {
    check_point: "Check electrical connection for tightness.",
  },
  {
    check_point: "Check pully for tightness.",
  },
  {
    check_point: "Lubricate the motor bearing.",
  },
  {
    check_point: "Check hand lever smooth operation.",
  },
];

export const Air_Leaking_Testing = [
  {
    check_point: "Change water.",
  },
  {
    check_point: "Check the water level.",
  },
  {
    check_point: "Clean the control panel by brush, air blower.",
  },
  {
    check_point: "Check the control panel.",
  },
  {
    check_point: "Clean the direction control valve.",
  },
  {
    check_point: "Check red bin sensor for working.",
  },
];

export const T_Drill_Machine = [
  {
    check_point: "Check the oil level.",
  },
  {
    check_point: "Check the oil leakage.",
  },
  {
    check_point: "Check the seals for leakage & damage.",
  },
  {
    check_point: "Check the electrical circuit panel for working.",
  },
  {
    check_point: "Check motor bearing for undue noise & greasing to be done.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
  {
    check_point: "Check the air leakage.",
  },
  {
    check_point: "Check the seafty guard.",
  },
];

export const High_Pressure_Leakage = [
  {
    check_point: "Change water.",
  },
  {
    check_point: "Check the water level.",
  },
  {
    check_point: "Check the water and air leakage.",
  },
  {
    check_point: "Check  the pneumatic seals.",
  },
  {
    check_point: "Clean the control panel by brush, air blower.",
  },
  {
    check_point: "Check the control panel.",
  },
  {
    check_point: "Clean the direction control valve.",
  },
  {
    check_point: "Check red bin sensor for working .",
  },
];

export const Bust_Testing_Machine = [
  {
    check_point: "Check the Electrical connections for tightness.",
  },
  {
    check_point: "Check the oil leakage.",
  },
  {
    check_point: "Check /clean the solenoid valve.",
  },
  {
    check_point: "Clean the control panel by brush, air blower.",
  },
  {
    check_point: "Check motor bearing for undue noise & greasing to be done.",
  },
  {
    check_point: "Check the push button for working.",
  },
  {
    check_point: "Check the machine for cleaning.",
  },
  {
    check_point: "Check the diafram seal.",
  },
];

export const ORing_Testing_Machine = [
  {
    check_point: "Check the Electrical connections for tightness.",
  },
  {
    check_point: "Check the air leakage.",
  },
  {
    check_point: "Check /clean the solenoid valve.",
  },
  {
    check_point: "Clean FRL.",
  },
  {
    check_point: "Check cylinder Fittings for tightness.",
  },
  {
    check_point: "Check the controller for proper working.",
  },
  {
    check_point: "Check the machine for cleaning.",
  },
];

export const Bending_Machine = [
  {
    check_point: "Clean HMI with soft & clean cloth.",
  },
  {
    check_point: "Check sliding guides for lubrication.",
  },
  {
    check_point: "Check lubrication tank for oil level.",
  },
  {
    check_point: "Check limit switches for working and firm mounting.",
  },
  {
    check_point: "Check the penumatic cylinder for leakage & damage.",
  },
  {
    check_point: "Check the electrical circuit panel for working.",
  },
  {
    check_point: "Clean control panel by brush , air blower.",
  },
  {
    check_point: "Clean Safety  sensors with soft cloth & Check for working.",
  },
  {
    check_point: "Check electrical connections for tightness.",
  },
  {
    check_point: "Clean FRL for proper working.",
  },
  {
    check_point:
      "Check clamp arm bearing for undue noise & greasing to be done.",
  },
];
