import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";

import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import useConfirm from "../../components/Control/useConfirm";
import { Wrapper } from "../../components/Control/Flexbox";
import CloudUpload from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const IRMResitersList = () => {
  const classes = useStyles();
  const { state } = useAuth();
  const [irmResisterData, setIRMResistersData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [render, setRender] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    "SAVE",
    "Are you sure you want to Delete this report?"
  );

  useEffect(() => {
    axios
      .get(`/api/getYearlyAndMonthlyWiseIRMResister/${selectedDate}`)
      .then((res) => setIRMResistersData(res.data));
  }, [selectedDate, render]);

  const handleDelete = async (id) => {
    // const isConfirm = window.confirm(
    //   "Are you sure you want to delete this document"
    // );
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      axios
        .delete(`/api/deleteIRMResisterById/${id}`)
        .then((res) => {
          alert("success deleted");
          setRender((prev) => !prev);
        })
        .catch((err) => console.error(err));
    }
  };
  const uploadFile = async (e, id) => {
    if (!window.confirm("Are you sure you want upload this Files")) return;
    const file = e.target.files[0];
    if (!file) return;
    let formData = new FormData();
    formData.append("files", file);
    axios
      .patch(`/api/uploadIRMResisterFile/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert("document upload successly");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteFile = async (id) => {
    if (!window.confirm("Are you sure you want delete this Files")) return;
    axios
      .delete(`/api/deleteIRMResisterFile/${id}`)
      .then((res) => {
        if (res.data) {
          alert("document deleted successly");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section>
      <div style={{}}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
          }}
        >
          INCOMING RAW MATERIAL REPORT
          <div>
            {(state.user.userType === 3 || state.user.userType === 2) && (
              <Link
                to={`/addEditIRMResister`}
                style={{ color: "white", textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#054a29",
                  }}
                  startIcon={<AddCircleIcon />}
                >
                  New Daily Entry
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="subheading-container">
          <p className="subheading-primary">
            ACTIVE <span className="subheading-secondary">REPORTS</span>
          </p>
        </div>
      </div>
      <div style={{ display: "flex", margin: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate || null}
          onChange={setSelectedDate}
        />
      </div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            {["#", "Received Date ", "Attach MTC", "Status", "Action"].map(
              (item, index) => (
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  {item}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        {irmResisterData && (
          <TableBody>
            {irmResisterData.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.irmResister_date)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: item.isSubmitted ? "green" : "yellow",
                      color: item.isSubmitted ? "white" : "black",
                      width: "250px",
                    }}
                  >
                    {item.isSubmitted ? "SUBMITTED" : "DRAFT"}
                  </TableCell>
                  <TableCell align="center">
                    <Wrapper justify="center">
                      <input
                        id="contained-button-file"
                        type="file"
                        name="pcs_imageOrPdf"
                        accept="image/png,image/jpeg,image/jpg/,application/pdf"
                        onChange={(e) => uploadFile(e, item._id)}
                        hidden
                      />
                      <label
                        htmlFor="contained-button-file"
                        style={{
                          marginBottom: "0",
                        }}
                      >
                        <Button
                          variant="outlined"
                          component="span"
                          color="secondary"
                        >
                          <CloudUpload style={{ marginRight: "1rem" }} />
                          Upload Picture / Pdf
                        </Button>
                      </label>
                      {item.file && (
                        <>
                          <a target="_blank" href={item.file}>
                            <IconButton
                              size="small"
                              style={{
                                marginLeft: "0.5rem",
                              }}
                            >
                              <VisibilityIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </a>
                          <IconButton
                            size="small"
                            onClick={() => deleteFile(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </>
                      )}
                    </Wrapper>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="View Incoming RM Resister" arrow>
                      <Link to={`/addEditIRMResister/${item._id}?isView=true`}>
                        <IconButton size="small">
                          <VisibilityIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Edit Incoming RM Resister" arrow>
                      <Link to={`/addEditIRMResister/${item._id}?isView=false`}>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <EditIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Delete Incoming RM Resister" arrow>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(item._id)}
                        style={{ marginLeft: "0.5rem" }}
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "1.4rem",
                            color: "#c80202",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <DialogDelete isSubmitContent={true} />
    </section>
  );
};

export default IRMResitersList;
