import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
//icons
import BallotIcon from "@material-ui/icons/Ballot";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import ListIcon from "@material-ui/icons/List";
// end icons

const Clause10 = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        Equipment / Inspection Equipment's Management
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        {/* <div> */}
        {/*   <Link to="/masterProcedureList?category=clause-10" className="panel"> */}
        {/*      <ListIcon style={{ fontSize: "3rem", color: "maroon" }} /> */}
        {/*   </Link> */}
        {/*   <p className="card-btn-para"> */}
        {/*     Master <span className="card-btn-span">Procedure</span> */}
        {/*   </p> */}
        {/* </div> */}
        {/* <div> */}
        {/*   <Link to="/machine_list" className="panel"> */}
        {/*     <BallotIcon style={{ fontSize: "3rem", color: "maroon" }} /> */}
        {/*   </Link> */}
        {/*   <p className="card-btn-para"> */}
        {/*     Master List of <span className="card-btn-span">Machine</span> */}
        {/*   </p> */}
        {/* </div> */}
        <div>
          <Link to="/maintenance_breakdown" className="panel">
            <DomainDisabledIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Maintenance <span className="card-btn-span">Module</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default Clause10;
