import React, { useEffect, useState, useRef } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { DatePicker } from "@material-ui/pickers";
// import Input from "../../components/Control/Input";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import SaveIcon from "@material-ui/icons/Save";
// import ClearAllIcon from "@material-ui/icons/ClearAll";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { DivStyled } from "./container.styled";
import { toSuperScript } from "../../components/Control/toSuperScript";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const AddEditInstrument = ({ setIsNewInstrumentModal, id }) => {
  const classes = dropdownStyles();
  const [instrumentData, setInstrumentData] = useState({});
  const inputElement = useRef(null);
  const [render, setRender] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState("");
  const [allDepartment, setAllDepartment] = useState([]);

  const [categoryData, setCategoryData] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "instrument",
    `Are you sure you want to ${id ? "update" : "save"} this ?`
  );

  function handleInstrumentData(e) {
    setInstrumentData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    axios
      .get("/api/getInstrumentCategory")
      .then((res) => {
        if (res.data) {
          setCategoryData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [render]);
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/get_instrumentById/${id}`)
        .then((res) => {
          setInstrumentData(res.data);
          setAutocompleteData(res.data?.department);
        })
        .catch((err) => console.log(err));
    }
    // else {
    //   setInstrumentData({
    //     date_orig: new Date(),
    //   });
    // }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/getAllDepartments`)
      .then((res) => {
        setAllDepartment(res.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSumbit = async () => {
    // e.preventDefault();
    if (instrumentData?.instrument_type === undefined) {
      alert("please select the instrument type");
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/update_instrument/${id}`, instrumentData)
        .then((res) => {
          setIsNewInstrumentModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/create/instrument", instrumentData)
        .then((res) => {
          setIsNewInstrumentModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <section className="root-admin">
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          {id ? "UPDATE" : "SUBMIT"}
        </Button>
      </div>
      <DivStyled>
        <label htmlFor="instrument_type">INSTRUMENT TYPE: </label>
        <FormControl
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            // width: "100%",
            marginRight: "6rem",
          }}
          fullWidth={true}
          variant="filled"
          classes={{
            root: classes.root,
          }}
        >
          <Select
            defaultValue={instrumentData?.instrument_type || ""}
            name="instrument_type"
            id="instrument_type"
            key={instrumentData?.instrument_type}
            classes={{
              root: classes.root,
              select: classes.select,
            }}
            style={{ fontSize: "0.8rem" }}
            disableUnderline
            onChange={handleInstrumentData}
          >
            {/* <MenuItem value=" ">
              <em>None</em>
            </MenuItem> */}
            {categoryData.map((category, index) => (
              <MenuItem value={category.name} key={index + 200.0202}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DivStyled>
      <DivStyled>
        <label htmlFor="description">DESCRIPTION:</label>
        <input
          type="text"
          id="description"
          name="description"
          ref={inputElement}
          // required
          defaultValue={instrumentData?.description}
          onBlur={handleInstrumentData}
        />
      </DivStyled>

      <DivStyled>
        <label htmlFor="id_no">UNIQUE ID NO.:</label>
        <input
          type="text"
          id="id_no"
          name="id_no"
          ref={inputElement}
          // required
          defaultValue={instrumentData?.id_no}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      {/* <DivStyled>
        <label htmlFor="make">MAKE :</label>
        <input
          type="text"
          id="make"
          name="make"
          defaultValue={instrumentData?.make}
          onBlur={handleInstrumentData}
        />
      </DivStyled> */}
      <DivStyled>
        <label htmlFor="department">DEPARTMENT :</label>
        <Autocomplete
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: "6rem",
          }}
          id="training_topics"
          options={allDepartment}
          getOptionLabel={(option) => option.department_name}
          getOptionSelected={(option, value) =>
            option.department_name === value?.department_name
          }
          value={autocompleteData}
          onChange={(e, value) => {
            instrumentData.department = value?._id;
            setAutocompleteData(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="range">RANGE :</label>
        <input
          type="text"
          id="range"
          name="range"
          defaultValue={instrumentData?.range}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="least_count">LEAST COUNT :</label>
        <input
          type="text"
          id="least_count"
          name="least_count"
          defaultValue={instrumentData?.least_count}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="frequency_cycle">CALIBRATION FREQ.: </label>
        <FormControl
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: "6rem",
          }}
          fullWidth={true}
          variant="filled"
          classes={{
            root: classes.root,
          }}
        >
          <Select
            defaultValue={instrumentData?.frequency_cycle || ""}
            name="frequency_cycle"
            key={instrumentData?.frequency_cycle}
            id="frequency_cycle"
            classes={{
              root: classes.root,
              select: classes.select,
            }}
            style={{ fontSize: "0.8rem" }}
            disableUnderline
            onChange={handleInstrumentData}
          >
            <MenuItem value=" ">
              <em>NONE</em>
            </MenuItem>
            <MenuItem value={1}>1 Month</MenuItem>
            <MenuItem value={3}>3 Month</MenuItem>
            <MenuItem value={6}>6 Month</MenuItem>
            <MenuItem value={12}>12 Month</MenuItem>
          </Select>
        </FormControl>
      </DivStyled>
      {/* <DivStyled>
        <label htmlFor="calibration_done_date">CALIBRATION ON DATE</label>
        <DatePicker
          id="calibration_done_date"
          name="calibration_done_date"
          format="dd/MM/yyyy"
          InputProps={{
            disableUnderline: true,
          }}
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
          }}
          value={instrumentData?.calibration_done_date}
          onChange={(date) => {
            instrumentData.calibration_done_date = date;
            setRender((prev) => !prev);
          }}
        />
      </DivStyled> */}
      {/* <DivStyled>
        <label htmlFor="delay">DELAY IF ANY:</label>
        <input
          type="text"
          id="delay"
          name="delay"
          defaultValue={instrumentData?.delay}
          onBlur={handleInstrumentData}
        />
      </DivStyled> */}
      {/* <DivStyled>
        <label htmlFor="acceptence_criteria">ACCEPTENCE CRITERIA:</label>
        <input
          type="text"
          id="acceptence_criteria"
          name="acceptence_criteria"
          defaultValue={instrumentData?.acceptence_criteria}
          onBlur={handleInstrumentData}
        />
      </DivStyled> */}
      <DivStyled>
        <label htmlFor="remarks">REMARKS:</label>
        <input
          type="text"
          id="remarks"
          name="remarks"
          defaultValue={instrumentData?.remarks}
          onBlur={handleInstrumentData}
        />
      </DivStyled>

      <DialogSubmit isSubmitContent={false} />
    </section>
  );
};

export default AddEditInstrument;
