import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import { Add, Remove } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Wrapper } from "../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import SelectPart from "./SelectPart";
import Modal from "../../components/Control/Modal";
import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../components/Control/useConfirm";
import { ButtonStyled, TableContainerStyled } from "../DailyProduction/styles";
import TableReadEditCell from "../../components/TableEditCell";
import { useQuery } from "../../components/Control/useQuery";
import SelectSupplier from "./SelectSupplier";
import formatDate from "../../components/Control/formatDate";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 200px;
        border: none;
        height: 50px;
        text-align:center;
    }

`;
// const dropdownStyles = makeStyles({
//   root: {
//     backgroundColor: "#fff",
//   },
//   select: {
//     "&:hover": {
//       backgroundColor: "#fff",
//     },
//     "&:focus": {
//       backgroundColor: "#fff",
//     },
//   },
//   underline: {
//     borderBottom: "0px solid red !important",
//     "&:hover": {
//       borderBottom: "0px solid rgba(0,0,0,0)",
//     },
//   },
// });
const AddEditIRMResister = () => {
  // const classes = dropdownStyles();
  const { id } = useParams();
  const [isSelectPartModal, setIsSelectPartModel] = useState(false);
  const [isSelectSupplierModal, setIsSelectSupplierModal] = useState(false);
  const [render, setRender] = useState(false);
  const indexSelectPart = useRef(0);
  const indexSelectSupplier = useRef(0);
  const history = useHistory();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const [irmResisterData, setIRMResisterData] = useState({
    irmResister_date: new Date(),
    processes: new Array(10).fill().map((_) => ({})),
  });

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );

  const handleChangeInput = (e, index) => {
    const name = e.target.name;
    const value = e.target.value;
    if (index === 0 || index) {
      const values = { ...irmResisterData };
      values.processes[index][name] = value;
      setIRMResisterData(values);
    }
  };
  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/getIRMResisterById/${id}`)
      .then((res) => {
        setIRMResisterData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handlePartDetails = (partId, value, index) => {
    irmResisterData.processes[indexSelectPart.current].part_id = partId;
    irmResisterData.processes[indexSelectPart.current].part_name = value;
    setRender((prev) => !prev);
  };
  const handleSupplierDetails = (SupplierId, value, index) => {
    irmResisterData.processes[indexSelectSupplier.current].supplier_id =
      SupplierId;
    irmResisterData.processes[indexSelectSupplier.current].supplier_name =
      value;
    setRender((prev) => !prev);
  };

  const resetPartDetails = () => {
    irmResisterData.processes[indexSelectPart.current].part_id = null;
    irmResisterData.processes[indexSelectPart.current].part_name = null;
    setRender((prev) => !prev);
  };
  const resetSupplierDetails = () => {
    irmResisterData.processes[indexSelectSupplier.current].supplier_id = null;
    irmResisterData.processes[indexSelectSupplier.current].supplier_name = null;
    setRender((prev) => !prev);
  };

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        irmResisterData.isSubmitted = bool;
        axios
          .put(`/api/updateIRMResisterById/${id}`, irmResisterData)
          .then((res) => {
            if (res.data.status === "success") {
              alert("updated successfully");
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        irmResisterData.isSubmitted = bool;
        axios
          .post(
            `/api/createIRMResister
          `,
            irmResisterData
          )
          .then((res) => {
            if (res.data.status === "success") {
              alert("created successfully");
              history.push("/incomingRMResisterList");
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 80;
    } else {
      document.getElementById("machinetable").scrollLeft -= 80;
    }
  };
  // console.log(irmResisterData);

  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <ButtonStyled
          left
          onClick={(e) => {
            handleScroll("left");
          }}
        >
          <div>
            <ArrowBackIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <ButtonStyled
          right
          onClick={(e) => {
            handleScroll("right");
          }}
        >
          <div>
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            INCOMING RAW MATERIAL SUMMARY REGISTER
          </Typography>
          {!isView && (
            <div style={{ display: "flex" }}>
              <Wrapper
                style={{
                  gap: "1rem",
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#003566",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    handleSubmitAndSave(false);
                  }}
                >
                  Save & Continue
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#064a29",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    handleSubmitAndSave(true);
                  }}
                >
                  Submit
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </div>
          )}
        </div>
        <TableContainerStyled id="machinetable">
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
          >
            <thead>
              <tr style={{ height: "70px" }}>
                <th
                  colSpan="16"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  colSpan={3}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC NO :ARC/F/QAD/3
                </th>
              </tr>
              <tr>
                <th
                  colSpan="16"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  INCOMING RAW MATERIAL SUMMARY REGISTER
                </th>
                <th colSpan={3} style={{ border: "1px solid black" }}>
                  REV NO./DATE:-00/10.10.2019
                </th>
              </tr>
              <tr>
                <th
                  colSpan={19}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  <Wrapper style={{ gap: "5px" }}>
                    RECEIVED DATE:
                    {isView ? (
                      formatDate(irmResisterData.irmResister_date)
                    ) : (
                      <DatePicker
                        name="date_performance"
                        format="dd/MM/yyyy"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{
                          backgroundColor: "#F8DE7E",
                          paddingLeft: "25px",
                          width: "150px",
                        }}
                        value={irmResisterData.irmResister_date}
                        onChange={(date) => {
                          irmResisterData.irmResister_date = date;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
                  </Wrapper>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "SNO.",
                  "INVOICE NO.",
                  "INVOICE  DATE",
                  "SUPPLIER NAME",
                  "PART NAME",
                  "RECEIVED DATE",
                  "PART NO./DIA",
                  "GRADE",
                  "HEAT NO.",
                  "LENGTH",
                  "QTY",
                  "INSPECTION DATE",
                  "INSPECTION REPORT NO.",
                  "QUALITY REMARKS",
                  "QUALITY HEAD",
                  "REMARKS",
                ].map((heading, index) => (
                  <th
                    key={index + 1.0001}
                    rowSpan={index === 13 ? 1 : 2}
                    colSpan={index === 13 ? 4 : 1}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
              <tr>
                {[
                  "OK QTY.",
                  "REWORK QTY.",
                  "ACCEPTED U/D QTY.",
                  "REJECTED QTY.",
                ].map((heading, index) => (
                  <th
                    key={index + 10.0002}
                    colSpan={1}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
              {irmResisterData.processes.map((item, index) => (
                <tr key={item._id || index + 30.0003}>
                  <th
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    {index + 1}
                  </th>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.invoice_no}
                      name="invoice_no"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                  >
                    {isView ? (
                      formatDate(item.invoice_date)
                    ) : (
                      <DatePicker
                        // label="pic Date"
                        name="date_performance"
                        format="dd/MM/yyyy"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                          width: "100px",
                        }}
                        value={item.invoice_date}
                        onChange={(date) => {
                          irmResisterData.processes[index].invoice_date = date;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
                  </td>
                  <td
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    {isView ? (
                      item?.supplier_name || item?.supplier_id?.supplier_name
                    ) : (
                      <Wrapper
                        justify="space-between"
                        style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                      >
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {item?.supplier_id?.supplier_name ||
                            item?.supplier_name ||
                            "Pick Supplier Name"}
                        </p>
                        {item?.supplier_name ||
                        item?.supplier_id?.supplier_name ? (
                          <Wrapper
                            className="pir_add_modal"
                            style={{ backgroundColor: "#003566" }}
                            justify="center"
                            onClick={() => {
                              item.supplier_id = null;
                              item.supplier_name = null;
                              setRender((prev) => !prev);
                            }}
                          >
                            <Remove />
                          </Wrapper>
                        ) : (
                          <Wrapper
                            className="pir_add_modal"
                            justify="center"
                            style={{ backgroundColor: "#495057" }}
                            onClick={() => {
                              indexSelectSupplier.current = index;
                              setIsSelectSupplierModal(true);
                            }}
                          >
                            <Add />
                          </Wrapper>
                        )}
                      </Wrapper>
                    )}
                  </td>
                  <td
                    style={{
                      width: "100px",
                      border: "1px solid black",
                      textAlign: "center",
                      padding: "0 0.4rem",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    {isView ? (
                      item?.part_name || item?.part_id?.part_name
                    ) : (
                      <Wrapper
                        justify="space-between"
                        style={{ width: "200px", backgroundColor: "#f8f9fa" }}
                      >
                        <p
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {item?.part_id?.part_name ||
                            item?.part_name ||
                            "Pick Part Name"}
                        </p>
                        {item?.part_name || item?.part_id?.part_name ? (
                          <Wrapper
                            className="pir_add_modal"
                            style={{ backgroundColor: "#003566" }}
                            justify="center"
                            onClick={() => {
                              item.part_id = null;
                              item.part_name = null;
                              setRender((prev) => !prev);
                            }}
                          >
                            <Remove />
                          </Wrapper>
                        ) : (
                          <Wrapper
                            className="pir_add_modal"
                            justify="center"
                            style={{ backgroundColor: "#495057" }}
                            onClick={() => {
                              indexSelectPart.current = index;
                              setIsSelectPartModel(true);
                            }}
                          >
                            <Add />
                          </Wrapper>
                        )}
                      </Wrapper>
                    )}
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                  >
                    {item.invoice_date && formatDate(irmResisterData.irmResister_date)}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.part_no_dia}
                      name="part_no_dia"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.grade}
                      name="grade"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.heat_no}
                      name="heat_no"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.length}
                      name="length"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="number"
                      value={item.qty}
                      name="qty"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    style={{ border: "1px solid black", textAlign: "center" }}
                  >
                    {isView ? (
                      formatDate(item.inspection_date)
                    ) : (
                      <DatePicker
                        // label="pic Date"
                        name="date_performance"
                        format="dd/MM/yyyy"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                          width: "100px",
                        }}
                        value={item.inspection_date}
                        onChange={(date) => {
                          irmResisterData.processes[index].inspection_date =
                            date;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.inspection_report_no}
                      name="inspection_report_no"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="number"
                      value={item.ok_qty}
                      name="ok_qty"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="number"
                      value={item.rework_qty}
                      name="rework_qty"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="number"
                      value={item.accepted_u_d_qty}
                      name="accepted_u_d_qty"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="number"
                      value={item.rejected_qty}
                      name="rejected_qty"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.quality_head}
                      name="quality_head"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <TableReadEditCell
                      {...{ index }}
                      isView={isView}
                      type="text"
                      value={item.remarks}
                      name="remarks"
                      handleChange={(e, index) => handleChangeInput(e, index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainerStyled>
      </div>
      <Modal
        openPopup={isSelectPartModal}
        title="Select PART"
        closeModal={(e) => setIsSelectPartModel(false)}
      >
        <SelectPart
          {...{ handlePartDetails, resetPartDetails }}
          processes={irmResisterData.processes[indexSelectPart.current]}
        />
      </Modal>
      <Modal
        openPopup={isSelectSupplierModal}
        title="Select Supplier"
        closeModal={(e) => setIsSelectSupplierModal(false)}
      >
        <SelectSupplier
          {...{ handleSupplierDetails, resetSupplierDetails }}
          processes={irmResisterData.processes[indexSelectSupplier.current]}
        />
      </Modal>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default AddEditIRMResister;
