import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "../../../components/Control/useQuery";

const selectChecklistPreventive = [
  "None",
  "Spot_Welding",
  "Grinding",
  "Bending_Machine_01",
  "Induction_Brazing_Machine",
  "NRV_Testing_Machine",
  "Pera_Flange_Machine",
  "Bending_Machine_02",
  "Flushing_Machine",
  "Pipe_Flairing_Machine",
  "Tig_Welding",
  "Clamping",
  "Ultrasonic_1",
  "Oven",
  "Farule_Fitting_Machine",
  "Magna_Flux",
  "Lazer_Marking",
  "Air_Decay",
  "Soco_Cutter_Machine",
  "Crimping_Machine",
  "Ss_Furnace",
  "Cutting_Machine",
  "Benjo_Press_Machine",
  "Deburring_Machine",
  "Oil_Flushing_Machine",
  "Polishing_Machine",
  "End_Forming_Machine",
  "Pad_Printing",
  "Centre_Lathe_Machine",
  "Hydraullic_Press_1",
  "Radial_Drill_Machine",
  "Ultrasonic_2",
  "Hydraullic_Press_2",
  "Surface_Grinder",
  "Blockage_Testing_Machine",
  "Drill_Machine",
  "Air_Leaking_Testing",
  "T_Drill_Machine",
  "High_Pressure_Leakage",
  "Bust_Testing_Machine",
  "ORing_Testing_Machine",
  "Bending_Machine",
];

const selectChecklistValuePredictive = [
  "None",
  "Flushing",
  "Leakage_Testing",
  "Pipe_Bending",
  "Ultrasonic",
];
const selectType = {
  preventive: selectChecklistPreventive,
  predictive: selectChecklistValuePredictive,
};
const SelectChecklist = ({ checkListCurrentID }) => {
  const [checkMachine, setCheckMachine] = useState("None");
  const history = useHistory();
  const query = useQuery();
  const documentType = query.get("documentType");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <FormControl
        style={{
          width: "250px",
          marginRight: "0.5rem",
          marginTop: "0.6rem",
          marginLeft: "0.6rem",
        }}
        variant="filled"
        className="select-field"
      >
        <InputLabel>Select Machine</InputLabel>
        <Select
          name="checkMachine"
          key={checkMachine}
          defaultValue={checkMachine}
          onChange={(e) => {
            setCheckMachine(e.target.value);
          }}
          className="spec-select-character"
        >
          {selectType[documentType] &&
            selectType[documentType].map((machine, index) => {
              return (
                <MenuItem key={index} value={machine}>
                  {machine.replace(/_/g, " ")}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <Button
        type="submit"
        variant="outlined"
        // color="white"
        size="large"
        style={{
          backgroundColor: "green",
          height: "50px",
          marginTop: "0.5rem",
        }}
        // startIcon={<PublishIcon />}
        onClick={() => {
          history.push(
            `/maintenanceForCheckedMachineForm/${checkListCurrentID}/${checkMachine}`
          );
        }}
      >
        OK
      </Button>
    </div>
  );
};

export default SelectChecklist;
